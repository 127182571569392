import React from 'react';

import {
  TextInput,
  SelectInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  required,
} from 'react-admin';

import Box from '@material-ui/core/Box';


export default function Validation(props) {
  const { formData, rest } = props


  return (
    <Box p={2} >

      <Box display='flex' flexDirection='row'>
        <Box mr={0.5} flexShrink={1}>
          <SelectInput label="method" source="context.httpMethod" {...rest} defaultValue='GET' validate={required()} choices={[
            { id: 'GET', name: 'GET' },
            { id: 'POST', name: 'POST' },
            { id: 'HEAD', name: 'HEAD' },
            { id: 'PUT', name: 'PUT' },
            { id: 'DELETE', name: 'DELETE' },
          ]}
          />
        </Box>
        <Box flexGrow={1}>
          <TextInput label="URL" source="context.httpUrl" defaultValue='' {...rest} validate={required()} fullWidth />
        </Box>
      </Box>

      <TextInput label="body" source="context.httpBody" defaultValue='' {...rest} multiline fullWidth minRows={3} />

      <ArrayInput source="context.httpHeaders" label="Headers">
        <SimpleFormIterator>
          <FormDataConsumer>
            {({ getSource, scopedFormData }) =>
              <Box display="flex" flexDirection="row" flexWrap="wrap" >
                <Box flex={1} mr="0.5em"><TextInput source={getSource('message')} label="Name" fullWidth /> </Box>
                <Box flex={1} ml="0.5em"><TextInput source={getSource('value')} label="Value" fullWidth /></Box>
              </Box>
            }
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>

      <TextInput label="Spoof" source="context.httpSpoof" defaultValue='' {...rest} fullWidth />

      <Box display="flex" flexDirection="row" alignContent="flex-start">
        <Box mr={1}>
          <SelectInput label="Field" source="context.httpField" defaultValue='code' {...rest} validate={required()} choices={[
            { id: 'code', name: 'status' },
            { id: 'header', name: 'header' },
            { id: 'cookie', name: 'cookie' },
            { id: 'body', name: 'body' },
            { id: 'image', name: 'image' },
          ]}
          />
        </Box>
        {
          formData.context && formData.context.httpField === 'header' ?
            <Box mr={1} flex={2}>
              < TextInput label="Header" defaultValue='' source="context.httpFieldHeader" validate={required()} {...rest} fullWidth />
            </Box>
            : null
        }
        <Box mr={1}>
          <SelectInput label="Criteria" defaultValue='==' source="context.httpCriteria" {...rest} validate={required()} choices={[
            { id: '==', name: '==' },
            { id: '!=', name: '!=' },
            { id: '>', name: '>' },
            { id: '=>', name: '=>' },
            { id: '<', name: '<' },
            { id: '<=', name: '<=' },
            { id: '~=', name: '~=' },
            { id: '!~=', name: '!~=' },
            { id: 'exists', name: 'exists' },
            { id: '!exists', name: 'does not exist' },
            { id: 'grayscale', name: 'grayscale' },
          ]}
          />
        </Box>
        <Box flex={2} >
          {
            formData.context && formData.context.httpCriteria && formData.context.httpCriteria.match(/\<|\>|\=/g) ?
              <TextInput label="Value" defaultValue='200' source="context.httpValue" {...rest} fullWidth />
              : null
          }

        </Box>
      </Box>
    </Box>
  )
}
