import {
  TextField,
  SimpleShowLayout,
  useRecordContext,
  FunctionField,
  RichTextField,
  Button,
} from 'react-admin';

import ReactJson from 'react-json-view'

import { Box, Tooltip, makeStyles } from '@material-ui/core';

import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import ReplayIcon from '@material-ui/icons/Replay';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';

import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { default as MuiTextField } from '@material-ui/core/TextField';


const useStyles = makeStyles({
  passed: { color: 'green', fontSize: "2rem" },
  failed: { color: 'red' },
  pending: { color: 'black' },
  completed: { color: 'mediumturquoise' },
  secondary: { color: 'grey' },
});

const statusIcons = {
  passed: <ThumbUpOutlinedIcon />,
  failed: <ThumbDownOutlinedIcon />,
  pending: <HourglassEmptyOutlinedIcon />,
}

export const parseUserVal = (field, name) => {
  if (field === null || field === undefined) {
    return "No data available";
  }
  const keyname = `val-${name}-`
  if (Array.isArray(field)) {
    return <List dense key={`${keyname}-list`}>
      {
        field.map((e, i) => <div key={`${keyname}-${i}-text`}><ListItem  >
          <ListItemText primary={e} />
        </ListItem>
          {field.length > 1 ? <Divider /> : null}
        </div>
        )
      }
    </List>
  }
  if (typeof field === 'boolean') {
    if (field) {
      return <List dense key={`${keyname}-boolean`} >
        <ListItem >
          <ListItemAvatar>
            <CheckBoxOutlinedIcon />
          </ListItemAvatar>
          <ListItemText key={`${keyname}-text`} primary="Yes" />
        </ListItem>
      </List>
    }
    else {
      return <List dense key={`${keyname}-${name}`}>
        <ListItem  >
          <ListItemAvatar>
            <CancelPresentationIcon />
          </ListItemAvatar>
          <ListItemText key={`${keyname}-text`} primary="No" />
        </ListItem>
      </List>
    }
  }
  try {
    const json = typeof field === 'string' ? JSON.parse(field) : field;
    if (json && typeof json === 'object') {
      return <ReactJson src={json} name={name} collapsed={false} enableClipboard={false} displayDataTypes={false} key={`${keyname}-${name}`} />;
    }
  } catch (error) {
    console.log(error)
    return <MuiTextField key={`${keyname}-text`} value={field} multiline disabled={true} />
  }
  return <MuiTextField key={`${keyname}-text`} value={field} multiline disabled={true} />
};

export const FeedbackBox = props => {
  const classes = useStyles();
  const { validation } = props;
  if (!validation) { return null; }
  return (<Box display="flex" flexDirection="row" flex={1} key={`feedbackBox-${validation.id}`}>
    <Box display="flex" flex={1} ml="0.5em" justifyContent="flex-start" flexDirection="column">
      <Box display="flex" flexDirection="row" className={classes['secondary']}>
        <Tooltip title='Answer'>
          <QuestionAnswerOutlinedIcon />
        </Tooltip>
        <Typography>&nbsp;Answer</Typography>
      </Box>
      <br />
      {parseUserVal(validation.answer, `answer-${validation.id}`)}
    </Box>
    <Box ml="1em" />
    <Divider orientation="vertical" flexItem />
    <Box ml="1em" />
    <Box display="flex" flex={1} flexDirection="column"  >
      <Box display="flex" flexDirection="row" className={classes['secondary']}>
        <Tooltip title='Feedback'>
          <FeedbackOutlinedIcon />
        </Tooltip>
        <Typography>&nbsp;Feedback</Typography>
      </Box>
      <br />
      {parseUserVal(validation.feedback, `feedback-${validation.id}`)}
    </Box>
  </Box>)
}


export const AttemptShow = props => {
  const record = useRecordContext(props);
  const classes = useStyles();

  if (!record) { return null }
  const status = record && record.passed ? 'passed' : record.completed || false ? 'failed' : 'pending';

  const StatusMessage = () => {
    return (
      <Tooltip title={status}>
        <span className={classes[status]} >
          {statusIcons[status]} &nbsp; {status.toUpperCase()}
        </span>
      </Tooltip>
    )
  }

  const resultMessage = () => {
    switch (status) {
      case 'passed':
        return "Lab.successMessage";
      case 'failed':
        return "Lab.failMessage";
      default:
        return "Lab.pendingMessage";
    }
  }

  const showValidation = (valId) => {

    const userValIndex = record.results.findIndex(result => result.ValidationId === valId);
    const userValidation = userValIndex > -1 ? record.results[userValIndex] : { answer: 'No answer available', feedback: ["No feedback available"] };
    const labValIndex = record.Lab.Validations.findIndex(result => result.id === valId);
    const labValidation = record.Lab.Validations[labValIndex];

    // const parsedAnswer = parseUserVal(userValidation.answer, `answer-${userValIndex}`);
    // const parsedFeedback = parseUserVal(userValidation.feedback, `feedback-${userValIndex}`);

    return (
      <Accordion key={`validation-${valId}`}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`"panel1${valId}-content"`}
          key={`"panel1${valId}-header"`}
        >

          <Box alignItems="center" flexWrap="no-wrap">
            {(userValidation && userValidation.completed) ?
              <Tooltip title='completed'>
                <span className={classes['completed']} >
                  <DoneOutlinedIcon />
                </span>
              </Tooltip>
              :
              <Tooltip title='pending'>
                <span className={classes['pending']} >
                  <HourglassEmptyOutlinedIcon />
                </span>
              </Tooltip>

            }

          </Box>
          <Box flexGrow={1}><Typography>&nbsp;{labValidation.message}</Typography></Box>
          <Box><Typography>{`${userValidation ? userValidation.score : 0}/${labValidation.value}`}</Typography></Box>

        </AccordionSummary>
        <AccordionDetails >
          <Box display="flex" mr="1em" flexDirection="column" style={{ width: '100%' }}>
            {
              labValidation.description && labValidation.description !== "" ?
                <Box mr="1em" flex={1} display="flex" flexDirection="column">
                  <Box display="flex" flexDirection="row" className={classes['secondary']}>
                    <Tooltip title="description">
                      <DescriptionOutlinedIcon />
                    </Tooltip>
                    <Typography >&nbsp;Description</Typography>
                  </Box>
                  <Typography>
                    <RichTextField source={`Lab.Validations.${labValIndex}.description`} placeholder="No description available" />
                  </Typography>
                </Box>
                : null
            }

            <FeedbackBox validation={userValidation} />
          </Box>
        </AccordionDetails>
      </Accordion>
    )
  }

  const validations = () => {
    return record.Lab.Validations.sort(function (a, b) { return a.order - b.order }).map(v => showValidation(v.id))
  }

  return (

    <SimpleShowLayout>
      <Box p="1em">
        <Box display="flex" mr="1em">
          <Box flex={2} mr="1em" display="flex" flexDirection="column">
            <Box display="flex" alignContent="center" flexDirection="row">
              <Box>
                <Tooltip title="Username" >
                  <PersonOutlineOutlinedIcon />
                </Tooltip>&nbsp;&nbsp;
              </Box>
              <Box>
                <TextField source="User.username" label="" />
              </Box>
            </Box>

            <Box display="flex" alignContent="center" flexDirection="row">
              <Box>
                <Tooltip title="Email" >
                  <MailOutlineIcon />
                </Tooltip>&nbsp;&nbsp;
              </Box>
              <Box>
                <TextField source="User.realEmail" label="" />
              </Box>
            </Box>

            <Box display="flex" alignContent="center" flexDirection="row">
              <Box>
                <Tooltip title="Start" >
                  <CalendarTodayOutlinedIcon />
                </Tooltip>&nbsp;&nbsp;
              </Box>
              <Box>
                <TextField source="startDate" label="Start" />
              </Box>
            </Box>

            <Box display="flex" alignContent="center" flexDirection="row">
              <Box>
                <Tooltip title="End" >
                  <EventAvailableOutlinedIcon />
                </Tooltip>&nbsp;&nbsp;
              </Box>
              <Box>
                <TextField source="endDate" label="End" />
              </Box>
            </Box>


            {record.completed ?
              <Box display="flex" alignContent="center" flexDirection="row">
                <Box>
                  <Tooltip title="Time Taken" >
                    <HourglassEmptyOutlinedIcon />
                  </Tooltip>&nbsp;&nbsp;
                </Box>
                <Box>
                  <TextField source="timeDiff" label="Time taken" />
                </Box>
              </Box>
              :
              null
            }


          </Box>
          <Box flex={2} mr="1em">
            <Box display="flex" flexDirection='row' flexWrap='wrap' >
              <Box>
                <StatusMessage />
                <br /><br />
              </Box>
              <Box ml={3}>
                {status === 'failed' ? <Button
                  href={`#/labs/${record.Lab.id}`}
                  label="retry"
                  key="retryButton"
                >
                  <ReplayIcon />
                </Button> : null}
              </Box>
            </Box>

            <Box>
              Points obtained:&nbsp;
              <FunctionField label="Passing Grade" render={record => `${record.finalScore}/${record.Lab.maxScore}`} />
            </Box>
            <Box>
              Grade obtained:&nbsp;
              <FunctionField label="Passing Grade" render={record => `${(Math.floor(record.finalScore * 100 / record.Lab.maxScore)) || 0}`} />
            </Box>
            <Box>
              Passing Grade:&nbsp;
              <FunctionField label="Passing Grade" render={record => `${record.Lab.passGrade * 20}`} />
            </Box>
          </Box>
        </Box>
        <Box display="flex" mr="1em">
          <RichTextField source={resultMessage()} />
        </Box>
        <Box display="flex" mr="1em" flexDirection="column">
          {
            validations()
          }
        </Box>
      </Box>



    </SimpleShowLayout>

  )
}