import * as React from "react";
import {
  TextField,
  TextInput,
  Datagrid,
  ReferenceArrayField,

} from 'react-admin';

import { List } from "@react-admin/ra-rbac";




const postFilters = [
  <TextInput label="Search" source="name" alwaysOn />,
];



export const AssetList = props => (
  <List {...props} hasCreate empty={false} perPage={25}
  >
    <Datagrid
      undoable
      // rowClick="edit"
      isRowSelectable={() => false}
    >
      <TextField source="resource" label="Asset" />
      <TextField source="type" label="Type" />
      <TextField source="reservations" label="Future Reservations" />
    </Datagrid>
  </List>
);

