import * as React from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import PublishIcon from '@material-ui/icons/Publish';
import { FilterList, FilterListItem } from 'react-admin';
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
  endOfToday,
  startOfTomorrow,
  endOfWeek,
  endOfMonth,
} from 'date-fns';

import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core';

const Card = withStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1, // display on the left rather than on the right of the list
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);




export const FilterSidebar = () => {
  return (
    <Card>
      <CardContent>
        <StatusFilter />
        <EndingFilter />
        <StartingFilter />
        <Submited />
      </CardContent>
    </Card>
  );
}

const Submited = () => (
  <FilterList label="Submited" icon={<PublishIcon />}>
    <FilterListItem
      label="Today"
      value={{
        updatedAt_gte: endOfYesterday().toISOString(),
        updatedAt_lte: startOfTomorrow().toISOString(),
      }}
    />
    <FilterListItem
      label="This week"
      value={{
        updatedAt_gte: startOfWeek(new Date()).toISOString(),
        updatedAt_lte: endOfWeek(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="Last week"
      value={{
        updatedAt_gte: subWeeks(startOfWeek(new Date()), 1).toISOString(),
        updatedAt_lte: startOfWeek(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="This month"
      value={{
        updatedAt_gte: startOfMonth(new Date()).toISOString(),
        updatedAt_lte: endOfMonth(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="Last month"
      value={{
        updatedAt_gte: subMonths(startOfMonth(new Date()), 1).toISOString(),
        updatedAt_lte: startOfMonth(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="Earlier"
      value={{
        updatedAt_gte: undefined,
        updatedAt_lte: subMonths(startOfMonth(new Date()), 1).toISOString(),
      }}
    />
  </FilterList>
);
const EndingFilter = () => (
  <FilterList label="Ending" icon={<AccessTimeIcon />}>
    <FilterListItem
      label="Today"
      value={{
        endDate_gte: endOfYesterday().toISOString(),
        endDate_lte: startOfTomorrow().toISOString(),
        startDate_gte: undefined,
        startDate_lte: undefined,
      }}
    />
    <FilterListItem
      label="This week"
      value={{
        endDate_gte: startOfWeek(new Date()).toISOString(),
        endDate_lte: endOfWeek(new Date()).toISOString(),
        startDate_gte: undefined,
        startDate_lte: undefined,
      }}
    />
    <FilterListItem
      label="Last week"
      value={{
        endDate_gte: subWeeks(startOfWeek(new Date()), 1).toISOString(),
        endDate_lte: startOfWeek(new Date()).toISOString(),
        startDate_gte: undefined,
        startDate_lte: undefined,
      }}
    />
    <FilterListItem
      label="This month"
      value={{
        endDate_gte: startOfMonth(new Date()).toISOString(),
        endDate_lte: endOfMonth(new Date()).toISOString(),
        startDate_gte: undefined,
        startDate_lte: undefined,
      }}
    />
    <FilterListItem
      label="Last month"
      value={{
        endDate_gte: subMonths(startOfMonth(new Date()), 1).toISOString(),
        endDate_lte: startOfMonth(new Date()).toISOString(),
        startDate_gte: undefined,
        startDate_lte: undefined,
      }}
    />
    <FilterListItem
      label="Earlier"
      value={{
        endDate_gte: undefined,
        endDate_lte: subMonths(startOfMonth(new Date()), 1).toISOString(),
        startDate_gte: undefined,
        startDate_lte: undefined,
      }}
    />
  </FilterList>
);
const StartingFilter = () => (
  <FilterList label="Starting" icon={<AccessTimeIcon />}>
    <FilterListItem
      label="Today"
      value={{
        endDate_gte: undefined,
        endDate_lte: undefined,
        startDate_gte: endOfYesterday().toISOString(),
        startDate_lte: startOfTomorrow().toISOString(),
      }}
    />
    <FilterListItem
      label="This week"
      value={{
        endDate_gte: undefined,
        endDate_lte: undefined,
        startDate_gte: startOfWeek(new Date()).toISOString(),
        startDate_lte: endOfWeek(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="Last week"
      value={{
        endDate_gte: undefined,
        endDate_lte: undefined,
        startDate_gte: subWeeks(startOfWeek(new Date()), 1).toISOString(),
        startDate_lte: startOfWeek(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="This month"
      value={{
        endDate_gte: undefined,
        endDate_lte: undefined,
        startDate_gte: startOfMonth(new Date()).toISOString(),
        startDate_lte: endOfMonth(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="Last month"
      value={{
        endDate_gte: undefined,
        endDate_lte: undefined,
        startDate_gte: subMonths(startOfMonth(new Date()), 1).toISOString(),
        startDate_lte: startOfMonth(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="Earlier"
      value={{
        endDate_gte: undefined,
        endDate_lte: undefined,
        startDate_gte: undefined,
        startDate_lte: subMonths(startOfMonth(new Date()), 1).toISOString(),
      }}
    />
  </FilterList>
);
const StatusFilter = () => (
  <FilterList
    label="Status"
    icon={<AssignmentTurnedInIcon />}
  >
    <FilterListItem
      label="Future"
      value={{
        endDate_gte: endOfToday().toISOString(),
        endDate_lte: undefined,
        startDate_gte: endOfToday().toISOString(),
        startDate_lte: undefined

      }}
    />
    <FilterListItem
      label="Active"
      value={{
        endDate_gte: endOfYesterday().toISOString(),
        endDate_lte: undefined,
        startDate_gte: undefined,
        startDate_lte: endOfToday().toISOString(),
      }}
    />
    <FilterListItem
      label="Inactive"
      value={{
        endDate_gte: undefined,
        endDate_lte: endOfYesterday().toISOString(),
        startDate_gte: undefined,
        startDate_lte: undefined,
      }}
    />
  </FilterList>
);
