import * as React from "react";

import {
  TextInput,
  SelectInput,
  NumberInput,
  useRecordContext,
  Button,
  FormDataConsumer,
  List,
  Datagrid,
  TextField,
  required,
  useNotify,
  useRefresh,
  useRedirect,
  TabbedForm,
  FormTab,
  translate,
  Toolbar,
  SaveButton,
  ReferenceField,
} from 'react-admin';

import RichTextInput from 'ra-input-rich-text';

import Http from './http'
import Dns from './dns'
import { YesNo, Multiple, Regex, UserVariable } from './core';
import Papi from './papi'
import AMP from "./amp";


import { Box } from '@material-ui/core';

import {
  EditDialog,
  CreateDialog,
} from "@react-admin/ra-form-layout";

import { Link } from 'react-router-dom';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

const validationTypes = [
  { id: 'http', name: 'HTTP' },
  { id: 'dns', name: 'DNS' },
  { id: 'amp', name: 'Akamai Media Player' },
  { id: 'PAPI', name: 'Akamai Property Manager' },
  { id: 'airflow', name: 'Async Job' },
  { id: 'uservariable', name: 'User Variable' },
  { id: 'yes/no', name: 'Yes/No Question' },
  { id: 'multiple', name: 'Multiple Choice Question' },
  { id: 'regex', name: 'Regular Expression (regex)' },
  { id: 'appsec', name: 'Akamai Security Configuration' },
]


const ValidationTitle = translate(({ record, translate }) => (
  < span >
    {record ? record.message : ''}
  </span >
));


export const ValidationList = props => (
  <>
    <List {...props} hasCreate={true}  >
      <Datagrid rowClick="edit">
        <TextField source="order" sortable={false} />
        <TextField source="message" sortable={false} />
        <TextField source="type" sortable={false} />
        <TextField source="value" sortable={false} />
        <ValidationEditModal />
      </Datagrid>
    </List>
    <ValidationEditModal />
  </>
)


const ReturnLabButton = ({ record }) => {
  // const classes = useStyles();
  // const validation = useRecordContext();
  return (
    <Button
      // className={classes.button}
      variant="contained"
      color="default"
      size="medium"
      component={Link}
      to={{
        pathname: `/admin/labs/${record.LabId}/2`,
        // Here we specify the initial record for the create view
        // state: { LabId: record.id },
      }}
      label="Return"
    >
      <KeyboardReturnIcon />
    </Button>

  );
}

const ValidationEditToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
    <ReturnLabButton {...props} />
  </Toolbar>
);

export const ValidationEditModal = props => {

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    notify(`Validation saved..`);
    redirect('edit', `/admin/labs/${data.LabId}`, 2);
    refresh();
  };

  const onAbort = ({ data }) => {
    console.log(data)
    notify(`Validation aborted..`);
    redirect('edit', `/admin/labs/${data.LabId}`, 2);
    refresh();
  };


  return (
    <EditDialog
      fullWidth
      maxWidth="md"
      onSuccess={onSuccess}
      onFailure={onSuccess}
      onAbort={onAbort}
      title={<ValidationTitle />}

      undoable="false"
      mutationMode="pessimistic"
      {...props}
    >

      <TabbedForm toolbar={<ValidationEditToolbar />}>
        <FormTab label="Info" fullWidth >
          <Box p={2} display="flex" flexDirection="column" sx={{ width: '95%' }}>

            <Box display="flex" flexDirection="row-reverse" flexWrap="wrap" >
              <Box ml={1} ><SelectInput source="type" choices={validationTypes} label="Type" /></Box>
              <Box ml={1}><NumberInput source="value" label="Value" step={1} /></Box>
            </Box>

            <Box>
              <TextInput source="message" label="Message" fullWidth />
              <RichTextInput source="description" label="Description" fullWidth />
              <RichTextInput source="feedback" label="Feedback upon failure" fullWidth />
            </Box>

          </Box>

        </FormTab>
        <FormTab label="Validation" fullWidth>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (formData.type) {
                if (formData.type === 'multiple') {
                  return <Multiple formData={formData} {...rest} />;
                }
                if (formData.type === 'yes/no') {
                  return <YesNo formData={formData} {...rest} />;
                }
                if (formData.type === 'regex') {
                  return <Regex formData={formData} {...rest} />;
                }
                if (formData.type === 'http') {
                  return <Http formData={formData} {...rest} />;
                }
                if (formData.type === 'dns') {
                  return <Dns formData={formData} {...rest} />;
                }
                if (formData.type === 'PAPI') {
                  return <Papi formData={formData} {...rest} />;
                }
                if (formData.type === 'uservariable') {
                  return <UserVariable formData={formData} {...rest} />;
                }
                if (formData.type === 'amp') {
                  return <AMP formData={formData} {...rest} />;
                }
              }
            }}
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </EditDialog>
  )
}



export const ValidationCreateModal = props => {
  const { LabId } = props.location.state;

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    notify(`Validation saved..`);
    redirect('edit', `/admin/labs/${data.LabId}`, 2);
    refresh();
  };

  const onAbort = ({ data }) => {
    console.log(data)
    notify(`Validation aborted..`);
    redirect('edit', `/admin/labs/${data.LabId}`, 2);
    refresh();
  };

  return (
    <CreateDialog {...props} onSuccess={onSuccess} onAbort={onAbort}
      title="Create new Validation">
      <TabbedForm
        toolbar={<ValidationEditToolbar />}
        initialValues={{ LabId: LabId, type: 'yes/no', value: 10, context: { answer: true } }}
      >
        <FormTab label="Info" fullWidth >
          <Box p={2} display="flex" flexDirection="column" sx={{ width: '95%' }}>

            <ReferenceField source="LabId" reference="admin/labs">
              <TextField source="name" label="Lab" />
            </ReferenceField>
            <Box display="flex" flexDirection="row-reverse" flexWrap="wrap" >
              <Box ml={1} ><SelectInput source="type" choices={validationTypes} label="Type" /></Box>
              <Box ml={1}><NumberInput source="value" label="Value" step={1} /></Box>
            </Box>

            <Box>
              <TextInput source="message" label="Message" fullWidth validate={required()} />
              <RichTextInput source="description" label="Description" fullWidth />
              <RichTextInput source="feedback" label="Feedback upon failure" fullWidth />
            </Box>

          </Box>

        </FormTab>
        <FormTab label="Validation" fullWidth>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (formData.type) {
                if (formData.type === 'multiple') {
                  return <Multiple formData={formData} {...rest} />;
                }
                if (formData.type === 'yes/no') {
                  return <YesNo formData={formData} {...rest} />;
                }
                if (formData.type === 'regex') {
                  return <Regex formData={formData} {...rest} />;
                }
                if (formData.type === 'http') {
                  return <Http formData={formData} {...rest} />;
                }
                if (formData.type === 'dns') {
                  return <Dns formData={formData} {...rest} />;
                }
                if (formData.type === 'PAPI') {
                  return <Papi formData={formData} {...rest} />;
                }
                if (formData.type === 'uservariable') {
                  return <UserVariable formData={formData} {...rest} />;
                }
                if (formData.type === 'amp') {
                  return <AMP formData={formData} {...rest} />;
                }
              }
            }}
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </CreateDialog>
  )
}
