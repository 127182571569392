import React from 'react';

import {
  TextInput,
  SelectInput,
  required,
} from 'react-admin';

import Box from '@material-ui/core/Box';


export default function AMP(props) {
  const { rest } = props
  return (
    <Box p={2} >

      <TextInput label="body" source="context.ampConfig"  {...rest} multiline fullWidth minRows={15}
        defaultValue='{
                        "autoplay":true,
                        "autoplayPolicy":"muted",
                        "media":
                        {
"src":"https://akamaitv.akamaized.net/hls/live/2005298/us-east-dr1/master.m3u8",
                        "type":"application/x-mpegURL"
},
                        "default":true,
                        "muted":true,
                        "playsinline":true,
                        "playoverlay":{"enabled":false},
                        "fullscreen":{"enabled":false},
                        "controls":false,
                        "loop":false
}'
      />

      <Box display='flex' flexDirection='row'>
        <Box mr={0.5} flexShrink={1}>
          <SelectInput label="Field" source="context.ampField" {...rest} defaultValue='playState' validate={required()} choices={[
            { id: 'playState', name: 'Play State' },
            { id: 'time', name: 'Time Elapsed' },
            { id: 'ended', name: 'Play Ended' },
            { id: 'body', name: 'Result Body' }
          ]}
          />
        </Box>
        <Box flexGrow={1}>
          <SelectInput label="Criteria" defaultValue='==' source="context.ampCriteria" {...rest} validate={required()} choices={[
            { id: '==', name: '==' },
            { id: '!=', name: '!=' },
            { id: '>', name: '>' },
            { id: '=>', name: '=>' },
            { id: '<', name: '<' },
            { id: '<=', name: '<=' },
            { id: '~=', name: '~=' },
            { id: '!~=', name: '!~=' },
            { id: 'exists', name: 'exists' },
            { id: '!exists', name: 'does not exist' }
          ]}
          />
        </Box>
        <Box flexGrow={1}>
          <TextInput label="Value" source="context.ampValue"  {...rest} fullWidth />
        </Box>
      </Box>

    </Box>
  )
}
