import * as React from 'react';


import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import { FilterList, FilterListItem } from 'react-admin';


import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core';

const Card = withStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1, // display on the left rather than on the right of the list
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);




export const FilterSidebar = () => {
  return (
    <Card>
      <CardContent>
        <ProvisionedFilter />
        <TerritoryFilter />
        <EnrollmentsFilter />
      </CardContent>
    </Card>
  );
}

const ProvisionedFilter = () => (
  <FilterList
    label="Provisioned"
    icon={<AccountBoxOutlinedIcon />}
  >
    <FilterListItem
      label="True"
      value={{
        provisioned: true,
      }}
    />
    <FilterListItem
      label="False"
      value={{
        provisioned: false,
      }}
    />
  </FilterList>
);

const EnrollmentsFilter = () => (
  <FilterList
    label="Enrollments"
    icon={<AssignmentOutlinedIcon />}
  >
    <FilterListItem
      label="10+"
      value={{
        enrollments: 100,
      }}
    />
    <FilterListItem
      label="1-10"
      value={{
        enrollments: 10,
      }}
    />
    <FilterListItem
      label="0"
      value={{
        enrollments: 0,
      }}
    />
  </FilterList>
);

const territories = [
  { id: 'apac', name: 'APAC' },
  { id: 'emea', name: 'EMEA' },
  { id: 'latam', name: 'LATAM' },
  { id: 'na', name: 'North America' },
  { id: 'japan', name: 'Japan' },
]

const TerritoryFilter = () => (
  <FilterList
    label="Territory"
    icon={<LanguageOutlinedIcon />}
  >
    {
      territories.map(t => (
        <FilterListItem key={t.id}
          label={t.name}
          value={{ territory: t.id }}
        />
      ))
    }

  </FilterList>
);
