import React, { useState, useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box'

const Regex = props => {

  const { validation, answered } = props;


  const [answer, setAnswer] = useState(validation.answer);

  useEffect(() => {
    if (answered) {
      setAnswer(validation.answers[0].answer)
    }
    else if (validation.answer) {
      setAnswer(validation.answer)
    }
    else {
      setAnswer('')
    }
  }, [validation.id])

  const handleTextFieldChange = (event) => {
    validation.answer = event.target.value
    setAnswer(event.target.value)
  }

  return (
    <Box p={3}>
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend">Use the textfield below to input the requested answer/value.</FormLabel>
        <br />
        <TextField
          name={`regex-${validation.id}`}
          id={`regex-${validation.id}`}
          label="Answer"
          variant="outlined"
          fullWidth
          disabled={answered}
          value={answer}
          required={true}
          onChange={handleTextFieldChange}
          multiline={validation.context.regexM}
          minRows={validation.context.regexM ? 5 : 1}
        />
      </FormControl>
    </Box>
  )
}

export default Regex;