import * as React from "react";
import {
  TextField,
  TextInput,
  DateField,
  required,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useCreateSuggestionContext,
  useCreate,
  ArrayField,
  SingleFieldList,
  ChipField,
  ReferenceArrayField,

} from 'react-admin';

import { List } from "@react-admin/ra-rbac";


import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField as MuiTextField,
} from '@material-ui/core';

import {
  EditableDatagrid,
  RowForm,
} from "@react-admin/ra-editable-datagrid";

const postFilters = [
  <TextInput label="Search" source="name" alwaysOn />,
];



export const TagList = props => (
  <List {...props} filters={postFilters} hasCreate empty={false} perPage={25}
  >
    <EditableDatagrid
      undoable
      createForm={<TagEditRow />}
      editForm={<TagEditRow />}
      rowClick="edit"
    // isRowSelectable={() => false}
    >
      <TextField source="name" label="Tag Name" />
      <DateField source="updatedAt" label="Last Update" />
      <TextField source="taggableTypes" label="Used on" />
      {/* <ArrayField source="Tag_Taggables" label="Used on">
        <SingleFieldList linkType={false} >
          <ChipField source="taggableType" />
        </SingleFieldList>
      </ArrayField> */}

    </EditableDatagrid>
  </List>
);



export const TagEditRow = props => (
  <RowForm {...props} initialValues={{ name: '', updatedAt: '2022-01-31' }}>

    <TextInput source="name" label="Tag Name" validate={required()} />
    <DateField source="updatedAt" label="Last Update" />
    <TextField source="taggableTypes" label="Used on" />
    {/* <ArrayField source="Tag_Taggables" label="Used on">
      <SingleFieldList linkType={false} >
        <ChipField source="taggableType" />
      </SingleFieldList>
    </ArrayField> */}
  </RowForm>
)

export const TagIdsArray = (props) => (
  <ReferenceArrayField label="Tags" reference="admin/tags" source="tagIds" {...props}>
    <SingleFieldList>
      <ChipField source="name" />
    </SingleFieldList>
  </ReferenceArrayField>
)

export const TagFieldArray = (props) => (
  <ArrayField source="tags"  >
    <SingleFieldList linkType={false}>
      <ChipField source="name" />
    </SingleFieldList>
  </ArrayField>
);


export const TagFilterArray = (props) => (
  <ReferenceArrayInput source="tags" reference="admin/tags" label="Tags" >
    <AutocompleteArrayInput
      optionText="name"
      optionValue="id"
      suggestionLimit={25}
      source="tags"
      key="tagFilterArray"
    />
  </ReferenceArrayInput>
);
export const TagInputArray = (props) => (
  <ReferenceArrayInput
    source="tags"
    reference="admin/tags"
    parse={(list) => {
      return list && list.map(id => {
        return { id };
      });
    }}
    format={(list) => {
      return list && list.map(i => i.id);
    }}
    label="Tags">
    <AutocompleteArrayInput
      suggestionLimit={5}
      create={<TagCreate />}
    />
  </ReferenceArrayInput>
);

export const TagCreate = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [value, setValue] = React.useState(filter || '');
  const [create] = useCreate('admin/tags');
  const handleSubmit = event => {
    event.preventDefault();
    create(
      {
        payload: {
          data: {
            name: value,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          setValue('');
          onCreate(data);
        },
      },
    );
  };
  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit} >
        <DialogContent>
          <MuiTextField
            label="New Tag"
            value={value}
            onChange={event => setValue(event.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};