import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { useHistory, useLocation } from "react-router-dom";

import { Title } from 'react-admin';
import { httpClient } from '../Common/functions'
import { FeedbackBox } from '../Common/attemptShow'
import { Multiple, UserVariable, YesNo } from './validations/core';
import Regex from './validations/regex';
import { AMP, ampValidate } from './validations/amp'

import {
  RichTextField,
  Button,
  useNotify,
} from 'react-admin';

import Typography from '@material-ui/core/Typography'

import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import CircularProgress from '@material-ui/core/CircularProgress'
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import FastRewindOutlinedIcon from '@material-ui/icons/FastRewindOutlined';
import FastForwardOutlinedIcon from '@material-ui/icons/FastForwardOutlined';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import PriorityHighRoundedIcon from '@material-ui/icons/PriorityHighRounded';

const apiUrl = process.env.REACT_APP_API_BACKEND;

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Menu = (props) => {
  return (
    <Box flex={1} mr={1} maxWidth="40%">
      <Card>
        <CardContent>
          <List component="nav" aria-label="main mailbox folders">

            {
              props.data.validations.map(v => (
                <Tooltip title={v.message} placement="right" key={v.id}>
                  <ListItem
                    button
                    selected={v.id === props.valId}
                    onClick={() => {
                      props.setValId(v.id);
                    }}>
                    <ListItemIcon>
                      {v.answers && v.answers.length > 0 ?
                        v.answers[0].id ?
                          <CheckRoundedIcon /> : <PriorityHighRoundedIcon />
                        :
                        <PlayArrowOutlinedIcon />
                      }
                    </ListItemIcon>
                    <ListItemText secondary={v.message} />
                  </ListItem>
                </Tooltip>
              ))
            }
          </List>
        </CardContent>
      </Card>
    </Box >
  )
}

const ValidationBody = (answered, validation) => {
  switch (validation.type) {
    case 'multiple':
      return <Multiple answered={answered} validation={validation} />;
    case 'amp':
      return <AMP answered={answered} validation={validation} />;
    case 'uservariable':
      return <UserVariable answered={answered} validation={validation} />;
    case 'yes/no':
      return <YesNo answered={answered} validation={validation} />;
    case 'regex':
      return <Regex answered={answered} validation={validation} />;
    default:
      return null;
  }
}

const MainBody = (props) => {
  const { labData, state, setState, loading, valId, setValId } = props
  switch (state) {
    case 'preamble':
      return <>
        <Typography variant="h4">{labData.name} </Typography>
        <br />
        <Typography variant="h6">{`Attempt ${labData.Attempts.length + labData.history.length + 1} out of ${labData.maxAttempts}`} </Typography>
        <Typography variant="h6">{`Total validations (tasks): ${labData.validations.length}`} </Typography>
        <br />
        <RichTextField source='description' record={labData} />

        <Box display="flex" justifyContent='center'>
          <Button
            label="Start"
            key="openLab"
            color='primary'
            size='large'
            onClick={() => { setState('starting') }}
            variant='contained'
            disabled={loading}
          >
            {
              loading ? <CircularProgress size={30} /> : <PowerSettingsNewOutlinedIcon />
            }

          </Button>
        </Box>
      </>;

    case 'validating':
    case 'validate':
      const validationIndex = labData.validations.findIndex(v => v.id === valId)
      if (validationIndex === -1) { return null }
      const validation = labData.validations[validationIndex];
      const answered = validation.answers && validation.answers.length > 0 && validation.answers[0].hasOwnProperty('id')
      return <>
        <Typography variant="h5">{validation.message} </Typography>
        <br />
        <Typography variant="h6">{answered ? `${validation.answers[0].score}/` : ''}{`${validation.value} points`} </Typography>
        <br />
        <RichTextField source='description' record={validation} />
        <br />
        {ValidationBody(answered, validation)}

        {answered ? <><br /><FeedbackBox validation={validation.answers[0]} /></> : null}

        <Box display="flex" justifyContent='center' alignContent='space-evenly' maxWidth="100%">
          <Box mr={2}>
            <Button
              label="Previous"
              key="prevVal-Button"
              color='default'
              size='medium'
              onClick={() => { setValId(labData.validations[validationIndex - 1].id) }}
              variant='contained'
              disabled={loading || validationIndex === 0}
            >
              <FastRewindOutlinedIcon />

            </Button>
          </Box>
          <Box>
            <Button
              label="Validate"
              key="validate-button"
              color='primary'
              size='medium'
              onClick={() => { setState('validate') }}
              variant='contained'
              disabled={loading || answered}
            >
              {
                loading ? <CircularProgress /> : <PlayCircleOutlineOutlinedIcon />
              }

            </Button>
          </Box>
          <Box ml={2}>
            <Button
              label="Next"
              key="nextVal-Button"
              color='default'
              size='medium'
              onClick={() => { setValId(labData.validations[validationIndex + 1].id) }}
              variant='contained'
              disabled={loading || validationIndex === (labData.validations.length - 1)}
            >
              <FastForwardOutlinedIcon />

            </Button>
          </Box>
        </Box>
      </>;

    default:
      return null;
  }
}

const Lab = (props) => {
  const { match } = props
  // const [labId, setLabId] = useState(match.params.labId || null)
  const [loading, setLoading] = useState(true)
  const [labData, setLabData] = useState(null)
  const [state, setState] = useState('preamble');
  const [valId, setValId] = useState(props.match ? props.match.params.valId : null)
  const history = useHistory();
  const notify = useNotify();

  const query = useQuery();
  const attemptId = query.get("attemptId")
  //

  //Load
  useEffect(() => {
    setLoading(true)
    const url = `${apiUrl}/labs/${match.params.labId || null}`;
    httpClient(url).then(({ json }) => {
      setLabData(json)
      //next unanswered validation
      if (json && json.validations && !valId) {
        let vid = json.validations.find(v => !(v.answers && v.answers.length > 0 && v.answers[0].id))
        if (vid) {
          setValId(vid.id)
        }
      }

      if (json.Attempts && json.Attempts.length > 0) {

        let passed = json.history.find(attempt => attempt.passed === true);
        let pending = json.Attempts.find(attempt => attempt.passed === false && attempt.completed === false);
        let incomplete = json.validations.filter(v => !(v.answers && v.answers.length > 0 && v.answers[0].id))

        if (passed) {//you already passed this lab
          history.push(`/attempts/${passed.id}/show`);
          return;
        }
        if (pending && incomplete.length === 0) {//all validated? => summary
          history.push(`/attempts/${pending.id}/show`);
          return;
        }
        else {//continue answering open attempt
          setState('validating')
        }

      }

    })
      .catch(err => {
        notify(err.message || 'Unable to start');
      })
      .finally(() => {
        setLoading(false);
      })

  }, []);

  useEffect(() => {
    //Start
    if (state === 'starting' && !loading) {
      setLoading(true)
      const url = `${apiUrl}/labs/${labData.id}`;

      let method = 'POST'
      let body = JSON.stringify([]);

      httpClient(url, {
        method: method,
        body: body,
      }).then(({ json }) => {
        labData.Attempts = [json]
        setState('validating')
      })
        .catch(err => {
          notify(err.message || 'Unable to start');
          setState('preamble')
        })
        .finally(() => {
          setLoading(false);
        })
    }

    //Autovalidate
    if (state === 'validating' && !loading) {
      const valIndex = labData.validations.findIndex(v => v.id === valId);
      const validation = labData.validations[Math.max(valIndex, 0)];
      //if answered, do nothing
      if (!(validation.answers && validation.answers.length > 0 && validation.answers[0].id)) {
        //auto-validate
        if (labData.autoValidate) {
          switch (validation.type) {
            case 'http':
            case 'dns':
            case 'PAPI':
            case 'airflow':
              setLoading(true);
              setTimeout(function () {//delay added for UX reasons
                setState('validate');
                setLoading(false);
              }, 1500);
              break;
            default:
              break;
          }
        }
      }
    }

    //Validate
    if (state === 'validate' && !loading) {
      setLoading(true)
      const url = `${apiUrl}/labs/${labData.id}`;
      const valIndex = labData.validations.findIndex(v => v.id === valId)
      const validation = labData.validations[valIndex]

      if (validation.type === 'amp' && !validation.answer) {
        ampValidate(validation, setLoading)
        return
      }
      let method = 'PUT'
      let body = JSON.stringify(
        {
          answer: validation.answer || {},
          validationId: valId,
          labId: labData.id,
          attemptId: labData.Attempts[0].id,
        }
      );
      httpClient(url, {
        method: method,
        body: body,
      }).then(({ json }) => {

        labData.validations[valIndex].answers[0] = json
        //next validation
        const nextValIndex = valIndex + 1
        //if next is unanswered
        if (nextValIndex < labData.validations.length) {
          const nextVal = labData.validations[nextValIndex];
          if (nextVal && (!nextVal.answers || nextVal.answers.length === 0 || !(nextVal.answers[0].hasOwnProperty('id')))) {
            setValId(nextVal.id)
          }
        }
        else {
          //all validated? => summary
          let incomplete = labData.validations.filter(v => !(v.answers && v.answers.length > 0 && v.answers[0].id))
          if (incomplete.length === 0) {
            setTimeout(function () { //delay added to sync up with attempt result calculation
              history.push(`/attempts/${labData.Attempts[0].id}/show`);
            }, 1500);
            return;

          }
        }

      })
        .catch(err => {
          notify(err.message || 'Unable to start');
        })
        .finally(() => {
          setState('validating')
          setLoading(false);

        })
    }

  }, [state, loading, valId]);

  if (!labData) { return null }

  //Legacy compatibility: redirect /labs/:id?attemptId:aid -> /attempts/aid
  if (attemptId) { history.push(`/attempts/${attemptId}/show`); return null; }

  return (
    <Box display="flex" flexDirection="row" minHeight={100}>
      {(labData.navigation && state !== 'preamble') ? <Menu data={labData} setValId={setValId} valId={valId} {...props} /> : null}
      <Box flex={4} minHeight={100}>
        <Card>
          <Title title={labData.name} />
          <CardContent>
            <MainBody labData={labData} state={state} setState={setState} loading={loading} valId={valId} setValId={setValId} />
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
};

export default Lab;