import * as React from "react";

import {
  TextField,
  TextInput,
  required,
} from 'react-admin';

import { List } from "@react-admin/ra-rbac";

import {
  EditableDatagrid,
  RowForm,
} from "@react-admin/ra-editable-datagrid";

// const ConfTitle = translate(({ record, translate }) => (
//   < span >
//     {record ? record.name : ''}
//   </span >
// ));

const postFilters = [
  <TextInput label="Search" source="any" alwaysOn />,
  <TextInput label="Name" source="name" />,
  <TextInput label="Section" source="section" />,
];

export const ConfList = props => (
  <List {...props} filters={postFilters} hasCreate>
    <EditableDatagrid
      undoable
      createForm={<ConfEditRow />}
      editForm={<ConfEditRow />}
      rowClick="edit"
    >
      <TextField source="name" label="Name" />
      <TextField source="section" label="Section" />
      <TextField source="description" label="Description" />
    </EditableDatagrid>
  </List>
);

// export const ConfEdit = (props) => {

//   return (
//     <Edit title={<ConfTitle />} {...props}>
//       <SimpleForm>
//         <TextInput source="name" label="Name" />
//         <TextInput source="section" label="Section" />
//         <TextInput source="description" multiline={true} label="Description" />
//         <TextInput source="value" label="Value" multiline={true} resettable />
//       </SimpleForm>
//     </Edit>
//   );
// }

export const ConfEditRow = props => (
  <RowForm {...props} initialValues={{ name: 'newName', section: 'lvs', description: 'editMe', value: 'newValue' }}>
    <TextInput source="name" label="Name" validate={required()} />
    <TextInput source="section" label="Section" validate={required()} />
    <TextInput source="description" multiline={true} label="Description" />
    <TextInput source="value" label="Value" multiline={true} resettable validate={required()} />
  </RowForm>
)