import * as React from 'react';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PersonAddOutlined from '@material-ui/icons/PersonAddOutlined';
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';


import { FilterList, FilterListItem } from 'react-admin';

import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core';

const Card = withStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1, // display on the left rather than on the right of the list
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);




export const FilterSidebar = () => {
  return (
    <Card>
      <CardContent>
        <PublishFilter />
        <EnrollmentFilter />
        <HiddenFilter />
      </CardContent>
    </Card>
  );
}

const PublishFilter = () => (
  <FilterList
    label="Published"
    icon={<CheckCircleOutlineIcon />}
  >
    <FilterListItem
      label="True"
      value={{
        published: true,
      }}
    />
    <FilterListItem
      label="False"
      value={{
        published: false,
      }}
    />
  </FilterList>
);

const EnrollmentFilter = () => (
  <FilterList
    label="Enrollments"
    icon={<PersonAddOutlined />}
  >
    <FilterListItem
      label="Enabled"
      value={{
        enrollment: true,
      }}
    />
    <FilterListItem
      label="Disabled"
      value={{
        enrollment: false,
      }}
    />
  </FilterList>
);

const HiddenFilter = () => (
  <FilterList
    label="Show Hidden"
    icon={<VisibilityOutlined />}
  >
    <FilterListItem
      label="True"
      value={{
        enabled: false,
      }}
    />
    <FilterListItem
      label="False"
      value={{
        enabled: true,
      }}
    />
  </FilterList>
);
