import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useUpdate,
  useNotify,
  FormWithRedirect,
  NumberInput,
  SelectInput,
  useRecordContext,
  FormDataConsumer,
} from 'react-admin';

import RichTextInput from 'ra-input-rich-text';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/styles/withStyles';

import IconContentEdit from '@material-ui/icons/Edit';
import IconCancel from '@material-ui/icons/Cancel';

import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography'
import Http from './http'
import Dns from './dns'
import { YesNo, Multiple, Regex } from './core';
import Papi from './papi'

const validationTypes = [
  { id: 'http', name: 'HTTP' },
  { id: 'dns', name: 'DNS' },
  { id: 'amp', name: 'Akamai Media Player' },
  { id: 'PAPI', name: 'Property Manager API' },
  { id: 'airflow', name: 'Async Job' },
  { id: 'uservariable', name: 'User Variable' },
  { id: 'yes/no', name: 'Yes/No Question' },
  { id: 'multiple', name: 'Multiple Choice Question' },
  { id: 'regex', name: 'Regular Expression (regex)' },
]

const MuiTabs = withStyles({
  root: {
    borderRight: '1px solid #CCC'
  }
})(Tabs);

const TabLabel = ({ text }) => (
  <Typography style={{
    width: '125px'
  }}>{text}</Typography>
)


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function ValidationEditButton({ onChange }) {
  const [showDialog, setShowDialog] = useState(false);
  // const [create, { loading }] = useCreate('admin/validations');

  const notify = useNotify();
  const form = useForm();
  const record = useRecordContext()
  const [tab, setTab] = useState(0);

  const [edit, { loading }] = useUpdate('admin/validations', record ? record.id : '');


  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    edit(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          // onChange();
          // form.change('LabId', data.id);

        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        }
      }
    );

  };



  const validationBody = (type, formData, rest) => {
    switch (type) {
      case 'http':
        return <Http formData={formData} rest={rest} />;
      case 'dns':
        return <Dns formData={formData} rest={rest} />;
      case 'yes/no':
        return <YesNo formData={formData} rest={rest} />;
      case 'multiple':
        return <Multiple formData={formData} rest={rest} />;
      case 'regex':
        return <Regex formData={formData} rest={rest} />;
      case 'PAPI':
        return <Papi formData={formData} rest={rest} />;
      default:
        return null;
    }
  }

  return (
    <>
      <Button onClick={handleClick} label="ra.action.edit">
        <IconContentEdit />
      </Button>
      {/* <TextField source="message" onClick={handleClick} /> */}
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Edit validation"
        maxWidth="lg"
      >


        <FormWithRedirect
          resource="admin/validation"
          save={handleSubmit}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>

              <FormDataConsumer>
                {({ formData, ...rest }) => formData.message && <DialogTitle>Edit validation {formData.message} </DialogTitle>
                }
              </FormDataConsumer>
              <DialogContent>
                <Tabs
                  value={tab}
                  onChange={(event, newValue) => { setTab(newValue) }}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab label="Info" />
                  <Tab label="Validation" />
                </Tabs>
                <TabPanel value={tab} index={0}>
                  <Box display="flex" flexDirection="column"  >
                    <Box display="flex" justifyContent="flex-end" >
                      <Box flex={1} mr={1}><NumberInput source="value" label="Value" step={1} /></Box>
                      <Box flex={2} ><SelectInput source="type" choices={validationTypes} label="Type" /></Box>
                    </Box>
                    <Box>
                      <TextInput source="message" label="Message" fullWidth />
                      <RichTextInput source="description" label="Description" />
                      <RichTextInput source="feedback" label="Feedback upon failure" />
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel value={tab} index={1}>
                  <FormDataConsumer>
                    {({ formData, ...rest }) => formData.type && validationBody(formData.type, formData, rest)
                    }
                  </FormDataConsumer>
                </TabPanel>

              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default ValidationEditButton;
