
import * as React from "react";

import {
  ReferenceManyField,
  DateField,
  ReferenceField,
  TextField,
  ReferenceInput,
  SelectInput,
  SearchInput,
  DateInput,
  TopToolbar,
  FilterButton,
  CreateButton,
  ExportButton,
  Button,
  TextInput,
  RadioButtonGroupInput,
  required,
  AutocompleteInput,
  email,
  SimpleForm,
  SaveButton,
  Toolbar,
  downloadCSV,
  useNotify,
  useRedirect,
  useRefresh,
  BooleanField,
  Show,
  SimpleShowLayout,
  ArrayField,
} from 'react-admin';

import jsonExport from 'jsonexport/dist';


import { List, Datagrid, WithPermissions } from "@react-admin/ra-rbac";


import {
  SelectColumnsButton,
  useSelectedColumns,
} from "@react-admin/ra-preferences";

import {
  EditableDatagrid,
  RowForm,
} from "@react-admin/ra-editable-datagrid";

import Person from '@material-ui/icons/Person';
import { TagInputArray, TagFilterArray, TagFieldArray } from "./tags";

import { makeStyles, Box } from '@material-ui/core';
import GroupAdd from '@material-ui/icons/GroupAdd';
import { differenceInDays, addWeeks, startOfWeek, addDays, isBefore, endOfYesterday } from 'date-fns'
import classnames from 'classnames';
import { FilterSidebar } from "./enrollmentsFilters";
import { CreateDialog } from "@react-admin/ra-form-layout";

const useStyles = makeStyles({
  expiring: { color: 'red' },
  expired: { color: 'gray' },
  future: { color: 'green' },
  close: { color: 'orange' },
});



const timeDiffNow = (date) => {

  const endDate = new Date(date)
  const timeDiff = differenceInDays(endDate, new Date())

  if (timeDiff > 7) {
    return 'future'
  }
  if (timeDiff <= 7 && timeDiff >= 1) {
    return 'close'
  }
  if (timeDiff < 1 && timeDiff >= -14) {
    return 'expiring'
  }
  if (timeDiff < -14) {
    return 'expired'
  }
}

const nextAvailableSlot = () => {
  const today = new Date();
  const tuesday = addDays(startOfWeek(today), 9);
  const nextAvailableStart = addWeeks(tuesday, 1);
  const nextAvailableEnd = addWeeks(tuesday, 5);
  return {
    startDate: nextAvailableStart.toISOString(),
    endDate: nextAvailableEnd.toISOString(),
  }

}

export const validateStartDate = (value, allValues) => {
  let today = endOfYesterday();
  const tuesday = addDays(startOfWeek(today), 9);
  const nextAvailableStart = addWeeks(tuesday, 1);
  if (isBefore(value, today)) {
    return "Date must be in the future"
  }
  if (isBefore(value, nextAvailableStart)) {
    return `Min start is ${nextAvailableStart.toDateString()}`
  }
  return undefined;
};
const validateStart = [required(), validateStartDate];

const EndDateField = props => {
  const classes = useStyles();
  if (props && props.record) {
    return (
      <DateField
        options={{ timeZone: 'UTC' }}
        className={classnames({
          [classes.future]: timeDiffNow(props.record[props.source]) === 'future',
          [classes.close]: timeDiffNow(props.record[props.source]) === 'close',
          [classes.expiring]: timeDiffNow(props.record[props.source]) === 'expiring',
          [classes.expired]: timeDiffNow(props.record[props.source]) === 'expired',
        })}
        {...props}
      />
    );
  }
  return null;
};

const StartDateField = props => {
  const classes = useStyles();
  if (props && props.record) {
    return (
      <DateField
        options={{ timeZone: 'UTC' }}
        className={classnames({
          null: true,
          [classes.future]: timeDiffNow(props.record[props.source]) === 'future',
        })}
        {...props}
      />
    )
  }
  return null;
};

const enrollmentListColumns = {
  create: <StartDateField source="updatedAt" label="Submited" />,
  start: <StartDateField source="startDate" label="Start" />,
  end: <EndDateField source="endDate" label="End" />,
  username: <ReferenceField label="Username" source="UserId" reference="admin/users">
    <TextField source="username" label="Username" />
  </ReferenceField>,
  provisioned: <ReferenceField label="Provisioned" source="UserId" reference="admin/users">
    <BooleanField source="provisioned" label="Provisioned" />
  </ReferenceField>,
  firstname: <ReferenceField label="First Name" source="UserId" reference="admin/users">
    <TextField source="firstName" label="First Name" />
  </ReferenceField>,
  lastname: <ReferenceField label="Last Name" source="UserId" reference="admin/users">
    <TextField source="lastName" label="Last Name" />
  </ReferenceField>,
  email: <ReferenceField label="Email" source="UserId" reference="admin/users">
    <TextField source="realEmail" label="Email" />
  </ReferenceField>,
  accId: <ReferenceField label="ACC" source="UserId" reference="admin/users">
    <TextField source="AccountId" label="ACC ID" />
  </ReferenceField>,
  lab: <ReferenceField source="LabId" reference="admin/labs">
    <TextField source="name" label="Lab" />
  </ReferenceField>,
  createdBy: <ReferenceField label="Created By" source="createdById" reference="admin/users">
    <TextField source="realEmail" label="Email" />
  </ReferenceField>,
  tags: <TagFieldArray label="Tags" />,
};

const referenceFilters = [
  <ReferenceInput label="Lab Select" source="LabId" reference="admin/labs" >
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput source="Username" reference="admin/users" >
    <SearchInput source="name" />
  </ReferenceInput>,

  // <TagFilterArray label="Tags" />,

]

const exporter = (records, fetchRelatedRecords, dataProvider) => {


  const data = records.map(record => ({
    startDate: record.startDate,
    endDate: record.endDate,
    submited: record.createdAt,
    lab: record.Lab.name,
    username: record.User.username,
    firstname: record.User.firstName,
    lastname: record.User.lastName,
    email: record.User.realEmail,
    acc: record.User.accountId,
    territory: record.User.territory,
    createdBy: record.createdBy ? record.createdBy.realEmail : null,
    tags: record.tags.map(t => { return { name: t.name } }),
  }));
  jsonExport(data, {
    headers: [],
    fillGaps: true,
  }, (err, csv) => {
    downloadCSV(csv, 'enrollments');
  });

};

const ListActions = (props) => (
  <TopToolbar>
    <FilterButton key="filters" />
    <SelectColumnsButton
      preference="enrollments.list.columns"
      columns={enrollmentListColumns}
    />
    <WithPermissions action="create" resource={props.resource}>
      <CreateButton />
    </WithPermissions>
    {/* Add your custom actions */}
    <WithPermissions action="bulkEnroll" resource={props.resource}>
      <Button
        href="#/admin/enrollments/bulkcreate"
        label="Bulk Enrollment"
        key="bulkCreateButton"
      >
        <GroupAdd />
      </Button>
    </WithPermissions>

    <ExportButton />
  </TopToolbar >
);





export const EnrollmentReference = (props) => {
  const columns = useSelectedColumns({
    preferences: `enrollmentsRef.${props.target || 'default'}.columns`,
    columns: enrollmentListColumns,
    omit: props.omit || [],
  });

  if (!props || !props.target) { return null; }

  return (

    <ReferenceManyField label="" reference="admin/enrollments" target={props.target}>
      <Datagrid isRowSelectable={record => false} >
        {columns}
      </Datagrid>
    </ReferenceManyField>
  )
}

export const EnrollmentsPerUser = (props) => {

  if (!props || !props.target) { return null; }

  return (

    <ReferenceManyField label="" reference="admin/enrollments" target={props.target}>
      <Datagrid isRowSelectable={record => false} s>
        <StartDateField source="updatedAt" label="Submited" />
        <StartDateField source="startDate" label="Start" />
        <EndDateField source="endDate" label="End" />
        <ReferenceField source="LabId" reference="admin/labs">
          <TextField source="name" label="Lab" />
        </ReferenceField>,
        <ReferenceField label="Created By" source="createdById" reference="admin/users">
          <TextField source="realEmail" label="Email" />
        </ReferenceField>,
        <TagFieldArray label="Tags" />,
      </Datagrid>
    </ReferenceManyField>
  )
}

const EnrollmentNotificationList = props => (
  <Show
    {...props}
    /* disable the app title change when shown */
    title=" "
  >
    <SimpleShowLayout>
      <ArrayField source="LabNotifications" label="Notifications history">
        <Datagrid>
          {/* <TextField source="pivot" /> */}
          <TextField source="template" />
          <TextField source="EnrollmentNotification.createdAt" label="Sent" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);


export const EnrollmentList = (props) => {
  const columns = useSelectedColumns({
    preferences: "enrollments.list.columns",
    columns: enrollmentListColumns,
    omit: ["nb_views"],
  });
  return (
    <>
      <List {...props}
        hasCreate
        perPage={10}
        sort={{ field: 'updatedAt', order: 'DESC' }}
        filters={referenceFilters}
        aside={<FilterSidebar />}
        actions={<ListActions />}
        exporter={exporter}
      >
        <EditableDatagrid
          undoable
          editForm={<EnrollmentEditRow />}
          rowClick="edit"
          expand={<EnrollmentNotificationList />}
          isRowSelectable={() => false}
        >
          {columns}

        </EditableDatagrid>
      </List>
      <EnrollmentCreateDialog {...props} />
    </>
  )
}

const enrollmentEditColumns = {
  create: <StartDateField source="updatedAt" label="Submited" />,
  start: <DateInput source="startDate" options={{ timezone: 'UTC' }} label="Start" />,
  end: <DateInput source="endDate" options={{ timezone: 'UTC' }} label="End" />,
  username: <ReferenceField label="User" source="UserId" reference="admin/users">
    <TextField source="username" label="Username" />
  </ReferenceField>,
  provisioned: <ReferenceField label="Provisioned" source="UserId" reference="admin/users">
    <BooleanField source="provisioned" label="Provisioned" />
  </ReferenceField>,
  firstname: <ReferenceField label="First Name" source="UserId" reference="admin/users">
    <TextField source="firstName" label="First Name" />
  </ReferenceField>,
  lastname: <ReferenceField label="Last Name" source="UserId" reference="admin/users">
    <TextField source="lastName" label="Last Name" />
  </ReferenceField>,
  email: <ReferenceField label="Email" source="UserId" reference="admin/users">
    <TextField source="realEmail" label="Email" />
  </ReferenceField>,
  lab: <ReferenceField source="LabId" reference="admin/labs">
    <TextField source="name" label="Lab" />
  </ReferenceField>,
  createdBy: <ReferenceField label="Created By" source="createdById" reference="admin/users">
    <TextField source="realEmail" label="Email" />
  </ReferenceField>,
  tags: <TagInputArray label="Tags" />,


};

export const EnrollmentEditRow = (props) => {
  const columns = useSelectedColumns({
    preferences: "enrollments.list.columns",
    columns: enrollmentEditColumns,
    omit: [],
  });
  return (
    <RowForm {...props} >
      {columns}
    </RowForm>
  )
}


const EnrollmentsCreateToolbar = props => {

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    notify(`Enrollment saved..`);
    refresh();
    redirect('list');
  };

  return (
    <Toolbar {...props} >
      <SaveButton
        label="Save and Show"
        redirect="show"
        onSuccess={onSuccess}
        submitOnEnter={true}
      />
      <SaveButton
        label="Save and Continue"
        redirect={false}
        submitOnEnter={false}
        variant="text"
      />
    </Toolbar>
  )
};

export const EnrollmentCreateDialog = (props) => {
  const defaultDates = nextAvailableSlot();
  const notify = useNotify();

  // const handleFailure = (error) => {
  //   console.log(error)
  //   notify(`${error.response.data.message || 'Something bad happened'}`)
  // };

  return (
    <CreateDialog title="New Enrollment" {...props} fullWidth >
      <SimpleForm
        label="Enrollment Info"
        icon={<Person />}
        toolbar={<EnrollmentsCreateToolbar />}
        initialValues={{ startDate: defaultDates.startDate }}
        fullWidth
      >

        <Box display="flex" fullWidth>
          <Box mr="1em" flex={5} flexDirection="column" >

            <ReferenceInput label="Lab" source="labId" optionValue="id" reference="admin/labs"  >
              <AutocompleteInput validate={required()} fullWidth />
            </ReferenceInput>

            <TextInput source="realEmail" label="Email" fullWidth isRequired={true} validate={[required(), email()]} />

            <TextInput source="firstName" label="First Name" fullWidth validate={required()} />

            <TextInput source="lastName" label="Last Name" fullWidth validate={required()} />

            <TextInput source="accountId" label="Account ID" fullWidth validate={required()} />

            <TextInput source="notes" label="Notes" fullWidth multiline={true} minRows={3} />

          </Box>
          <Box mr="1em" flex={1}>

            <DateInput source="startDate" options={{ timeZone: 'UTC' }} label="Start" validate={validateStart} />

            <RadioButtonGroupInput
              source="territory"
              validate={required()}
              row={false}
              choices={[
                { id: 'apac', name: 'APAC' },
                { id: 'emea', name: 'EMEA' },
                { id: 'latam', name: 'LATAM' },
                { id: 'na', name: 'North America' },
                { id: 'japan', name: 'Japan' },
              ]} />

            {/* <TagInputArray /> */}
            <RadioButtonGroupInput
              source="tag"
              label='Type'
              validate={required()}
              defaultValue='FullCourse'
              choices={[
                { id: 'FullCourse', name: 'FullCourse' },
                { id: 'ExamOnly', name: 'ExamOnly' },
              ]} />
          </Box>
        </Box>
      </SimpleForm>
    </CreateDialog>
  );
}