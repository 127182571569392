import { fetchUtils } from 'react-admin';

export const tryParseJSON = (jsonString) => {
  try {
    const o = JSON.parse(jsonString.toString());

    if (o && typeof o === "object") {
      return o;
    }
  }
  catch (e) {
    return jsonString;
  }

  return jsonString;
};

export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};