import React from 'react';

import {
  TextInput,
  SelectInput,
  required,
} from 'react-admin';

import Box from '@material-ui/core/Box';


export default function Validation(props) {
  const { formData, rest } = props
  return (
    <>
      <Box display="flex" flexDirection="row" flexWrap="wrap" >
        <Box flexShrink={1} mr={1}>
          <SelectInput label="Record" source="context.dnsType" defaultValue='A'  {...rest} validate={required()} choices={[
            { id: 'A', name: 'A' },
            { id: 'AAA', name: 'AAA' },
            { id: 'CNAME', name: 'CNAME' },
            { id: 'TXT', name: 'TXT' },
            { id: 'MX', name: 'MX' },
            { id: 'SOA', name: 'SOA' },
            { id: 'NAPTR', name: 'NAPTR' },
            { id: 'SRV', name: 'SRV' },
            { id: 'ANY', name: 'ANY' },
          ]}
          />
        </Box>
        <Box flex={4}>
          <TextInput label="Query" source="context.dnsQuery" {...rest} validate={required()} fullWidth />
        </Box>

      </Box>

      <TextInput label="Spoof" source="context.dnsResolver" {...rest} fullWidth />
      <Box display="flex" flexDirection="row" alignContent="flex-start">
        <Box mr={1}>
          <SelectInput label="Field" defaultValue='response' source="context.dnsResponse" {...rest} validate={required()} choices={[
            { id: 'response', name: 'response' },
          ]}
          />
        </Box>

        <Box mr={1}>
          <SelectInput label="Criteria" defaultValue='==' source="context.dnsCriteria" {...rest} validate={required()} choices={[
            { id: '==', name: '==' },
            { id: '!=', name: '!=' },
            { id: '>', name: '>' },
            { id: '=>', name: '=>' },
            { id: '<', name: '<' },
            { id: '<=', name: '<=' },
            { id: '~=', name: '~=' },
            { id: '!~=', name: '!~=' },
            { id: 'exists', name: 'exists' },
            { id: '!exists', name: 'does not exist' },
          ]}
          />
        </Box>
        <Box flex={2} >
          <TextInput label="Value" source="context.dnsValue" {...rest} fullWidth />
        </Box>
      </Box>
    </>
  )
}
