import * as React from "react";
import { Route } from 'react-router-dom';
import { RouteWithoutLayout } from 'react-admin';
import { EnrollmentCreateDialog } from "./Admin/enrollmentBulkCreate";
import Lab from './Student/lab'

export default [
  <Route exact path="/admin/enrollments/bulkcreate" component={EnrollmentCreateDialog} />,
  // <RouteWithoutLayout exact path="/labs/:labId" component={Lab} />,
  // <RouteWithoutLayout exact path="/labs/:labId/:valId" component={Lab} />,
  <Route exact path="/labs/:labId" component={Lab} />,
  <Route exact path="/labs/:labId/:valId" component={Lab} />,

];