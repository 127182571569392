import * as React from "react";
import {
  TextField,
  TextInput,
  SimpleForm,
  Create,
  ReferenceField,
  NumberInput,
  SelectInput,
  SelectField,
  FormDataConsumer,
  ReferenceInput,
  required,
  NumberField,
  useRecordContext,
} from 'react-admin';

import { List } from "@react-admin/ra-rbac";

import RemoveIcon from '@material-ui/icons/Remove';

import {
  EditableDatagrid,
  RowForm,
} from "@react-admin/ra-editable-datagrid";


import { useLocation } from 'react-router';
import { Box } from '@material-ui/core'

const pivotChoices = [
  { id: 'enrollment', name: 'Upon ENROLLMENT' },
  { id: 'provision', name: 'Upon PROVISIONING' },
  { id: 'startDate', name: 'Before to START date' },
  { id: 'completion', name: 'Upon COMPLETION' },
  { id: 'endDate', name: 'Before END date' },
]

const CustomNumberField = (props) => {
  const record = useRecordContext(props);
  if (record.pivot === "endDate" || record.pivot === "startDate") {
    return <NumberField
      {...props}
    />
  }
  return <RemoveIcon />;
}

const CustomSelectField = (props) => {
  const record = useRecordContext(props);
  if (record.pivot === "endDate" || record.pivot === "startDate") {
    return <SelectField
      choices={[
        { id: 'd', name: 'days' },
        { id: 'h', name: 'hours' },
      ]}
      {...props}
    />
  }
  return <RemoveIcon />;
}

const notificaitonFilters = [
  <ReferenceInput label="Lab Select" source="LabId" reference="admin/labs" alwaysOn>
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

export const NotificationList = props => (
  <>
    <List {...props}
      hasCreate
      filters={notificaitonFilters}
      bulkActionButtons={false}
      empty={false}
      perPage={25}
    >
      <EditableDatagrid
        undoable
        createForm={<NotificationCreateRow />}
        editForm={<NotificationEditRow />}
        rowClick="edit"
      >
        <ReferenceField label="Lab" source="LabId" reference="admin/labs">
          <TextField source="name" />
        </ReferenceField>

        <CustomNumberField source="span" label="Span" />
        <CustomSelectField label="Meassure" source="meassure" />

        <SelectField
          source="pivot"
          label="When"
          choices={pivotChoices} />
        <TextField source="template" label="Template Name" />
      </EditableDatagrid>
    </List>
  </>
);

export const NotificationGrid = props => (
  <EditableDatagrid
    editForm={<NotificationEditRow />}
    createForm={<NotificationCreateRow />}
    rowClick="edit"
  >

    <FormDataConsumer>
      {({ formData, ...rest }) => (formData.pivot === "startDate" || formData.pivot === "endDate") ?
        <NumberField label="Span" />
        : <RemoveIcon />
      }
    </FormDataConsumer>

    <FormDataConsumer>
      {({ formData, ...rest }) => (formData.pivot === "startDate" || formData.pivot === "endDate") ?
        <SelectField
          label="Meassure"
          source="meassure"
          choices={[
            { id: 'd', name: 'days' },
            { id: 'h', name: 'hours' },
          ]} />
        : <RemoveIcon />
      }
    </FormDataConsumer>
    <SelectField
      source="pivot"
      label="When"
      choices={pivotChoices} />
    <TextField source="template" label="Template Name" />
  </EditableDatagrid>
);


export const NotificationEditRow = props => (
  <RowForm {...props} initialValues={{ span: 1, template: '', meassure: 'd', pivot: 'enrollment' }}>
    <ReferenceField label="Lab" source="LabId" reference="admin/labs">
      <TextField source="name" />
    </ReferenceField>
    <FormDataConsumer>
      {({ formData, ...rest }) => (formData.pivot === "startDate" || formData.pivot === "endDate") ?
        <NumberInput label="Span" source="span" max={99} min={1} step={1} validate={required()} />
        : <RemoveIcon />
      }
    </FormDataConsumer>

    <FormDataConsumer>
      {({ formData, ...rest }) => (formData.pivot === "startDate" || formData.pivot === "endDate") ?
        <SelectInput
          label="Meassure"
          source="meassure"
          validate={required()}
          choices={[
            { id: 'd', name: 'days' },
            { id: 'h', name: 'hours' },
          ]} />
        : <RemoveIcon />
      }
    </FormDataConsumer>
    <SelectInput
      source="pivot"
      label="When"
      validate={required()}
      choices={pivotChoices} />
    <TextInput source="template" label="Template Name" validate={required()} />
  </RowForm>
);

export const NotificationCreateRow = props => {
  // Read the post_id from the location
  const location = useLocation();
  const LabId =
    location.state && location.state.LabId
      ? location.state.LabId
      : undefined;
  const initialValues = { LabId: LabId, span: 1, template: '', meassure: 'd', pivot: 'enrollment' }


  return (
    <RowForm {...props} initialValues={initialValues}>
      <ReferenceInput label="Lab" source="LabId" reference="admin/labs" validate={required()}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) => (formData.pivot === "startDate" || formData.pivot === "endDate") ?
          <NumberInput label="Span" source="span" max={99} min={1} step={1} validate={required()} />
          : <RemoveIcon />
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData, ...rest }) => (formData.pivot === "startDate" || formData.pivot === "endDate") ?
          <SelectInput
            label="Meassure"
            source="meassure"
            validate={required()}
            choices={[
              { id: 'd', name: 'days' },
              { id: 'h', name: 'hours' },
            ]} />
          : <RemoveIcon />
        }
      </FormDataConsumer>

      <SelectInput
        source="pivot"
        label="When"
        validate={required()}
        choices={pivotChoices} />
      <TextInput source="template" label="Template Name" validate={required()} />
    </RowForm>
  )
};



export const NotificationCreate = props => {
  // Read the LabId from the location
  const location = useLocation();
  const LabId =
    location.state && location.state.record
      ? location.state.record.LabId
      : undefined;

  const redirect = LabId ? `/admin/labs/${LabId}/edit/3` : false;

  return (
    <Create {...props}>
      <SimpleForm
        initialValues={{ span: 1, template: '', meassure: 'd', pivot: 'enrollment' }}
        redirect={redirect}
      >
        <ReferenceField label="Lab" source="LabId" reference="admin/labs">
          <TextField source="name" />
        </ReferenceField>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.pivot && formData.pivot !== 'enrollment' &&
            <Box display="flex" flexDirection="row" flexWrap="no-wrap" >
              <Box flex={1}>
                <NumberInput label="Span" source="span" max={99} min={1} step={1} validate={required()} />
              </Box>
              <Box flex={2}>
                <SelectInput
                  label="Meassure"
                  source="meassure"
                  validate={required()}
                  choices={[
                    { id: 'd', name: 'days' },
                    { id: 'h', name: 'hours' },
                  ]} />
              </Box>
            </Box>

          }
        </FormDataConsumer>


        <SelectInput
          source="pivot"
          label="When"
          validate={required()}
          choices={pivotChoices} />
        <TextInput source="template" label="Template Name" validate={required()} />
      </SimpleForm>
    </Create>
  );
};