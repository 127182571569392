import * as React from "react";

import {
  List,
  Datagrid,
  TextField,
  Edit,
  Create,
  TextInput,
  TabbedForm,
  FormTab,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  RadioButtonGroupInput,
  required,
  email,
  BooleanField,
  ExportButton,
  TopToolbar,
  FilterButton,
  CreateButton,
  translate,
  useRecordContext,
} from 'react-admin';

import {
  SelectColumnsButton,
  useSelectedColumns,
} from "@react-admin/ra-preferences";


import { FilterSidebar } from "./usersFilters";
import { EnrollmentsPerUser } from "./enrollments";
import { TagIdsArray, TagInputArray } from "./tags";
import { Box } from '@material-ui/core';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


import Person from '@material-ui/icons/Person';
import Style from '@material-ui/icons/Style';
import Group from '@material-ui/icons/Group';




const UserTitle = translate(({ record, translate }) => (
  < span >
    {/* {record ? translate('user.edit.username', { username: record.username }) : ''} */}
    {record ? record.realEmail : ''}
  </span >
));

const postFilters = [
  <TextInput label="Search" source="any" alwaysOn />,
  <TextInput label="Email" source="email" />,
];

const userListColumns = {
  username: < TextField source="username" label="Username" />,
  email: <TextField source="realEmail" label="Email" />,
  labs: <TextField source="labs" label="Unique Labs" />,
  attempts: <TextField source="attempts" label="Lab Attempts" />,
  enrollments: <TextField source="enrollments" label="Enrollments" />,
  territory: <TextField source="territory" label="Territory" />,
  provisioned: <BooleanField source="provisioned" label="Provisioned" />,
  tags: <TagIdsArray label="Tags" />,
}

const ListActions = (props) => (
  <TopToolbar>
    <FilterButton />
    <SelectColumnsButton
      preference="users.list.columns"
      columns={userListColumns}
    />
    <CreateButton />
    <ExportButton />
    {/* Add your custom actions */}
  </TopToolbar>
)

export const UserList = props => {
  const columns = useSelectedColumns({
    preferences: "users.list.columns",
    columns: userListColumns,
  });

  return (
    <List
      {...props}
      filters={postFilters}
      perPage={25}
      aside={<FilterSidebar />}
      actions={<ListActions />}
    >
      <Datagrid rowClick="edit" >
        {columns}
      </Datagrid>
    </List >
  );
}

const UserProvisioned = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={record[source]}
          name={`user-provisioned`}
          color="primary"
          // disabled={true}
          readOnly={true}
        />
      }
      label="Provisioned"
      key={`checkbox-proviusioned`}
    />
  )
}

export const UserEdit = (props) => {


  return (
    <Edit title={<UserTitle />} {...props} >
      <TabbedForm>
        <FormTab label="User Info" icon={<Person />} >
          {/* <TextInput source="username" disabled={true} fullWidth />
          <TextInput source="realEmail" label="Email" validate={[required(), email()]} isRequired={true} fullWidth disabled={true} />
          <TextInput source="firstName" label="First Name" fullWidth />
          <TextInput source="lastName" label="Last Name" fullWidth />
          <TextInput source="accountId" label="Account ID" fullWidth />
          <TextInput source="notes" label="Notes" fullWidth multiline minRows={3} />
          <RadioButtonGroupInput source="territory"
            row={false}
            choices={[
              { id: 'apac', name: 'APAC' },
              { id: 'emea', name: 'EMEA' },
              { id: 'latam', name: 'LATAM' },
              { id: 'na', name: 'North America' },
              { id: 'japan', name: 'Japan' },
            ]} />
          <BooleanInput source="test" label="Test User" />
          <UserProvisioned source="provisioned" /> */}
          <Box display="flex" fullWidth>

            <Box mr="1em" flex={1} flexDirection="column" >
              <Box>
                <TextInput source="username" disabled={true} fullWidth />
              </Box>
              <Box>
                <TextInput source="realEmail" label="Email" validate={[required(), email()]} isRequired={true} fullWidth disabled={true} />
              </Box>
              <Box>
                <TextInput source="firstName" label="First Name" fullWidth />
              </Box>
              <Box>
                <TextInput source="lastName" label="Last Name" fullWidth />
              </Box>
              <Box>
                <TextInput source="accountId" label="Account ID" fullWidth />
              </Box>
              <Box>
                <TextInput source="notes" label="Notes" fullWidth multiline minRows={3} />
              </Box>
            </Box>
            <Box mr="1em" flex={1}>


              <RadioButtonGroupInput source="territory"
                row={false}
                choices={[
                  { id: 'apac', name: 'APAC' },
                  { id: 'emea', name: 'EMEA' },
                  { id: 'latam', name: 'LATAM' },
                  { id: 'na', name: 'North America' },
                  { id: 'japan', name: 'Japan' },
                ]} />

              <BooleanInput source="test" label="Test User" />
              <UserProvisioned source="provisioned" />

            </Box>
            <Box mr="1em" flex={1}></Box>

          </Box>
        </FormTab>
        <FormTab label="Tags and Vars" icon={<Style />}>
          <TagInputArray />
          <ArrayInput source="variables" >
            <SimpleFormIterator>
              <TextInput source="name" label="Name" />
              <TextInput source="value" label="Value" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Enrollments" icon={<Group />}>
          <EnrollmentsPerUser target="UserId" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

export const UserCreate = (props) => {
  return (
    <Create title="New User" {...props}>
      <TabbedForm >
        <FormTab label="User Info" icon={<Person />} >

          <Box display="flex" fullWidth>

            <Box mr="1em" flex={1} flexDirection="column" >

              <Box>
                <TextInput source="realEmail" label="Email" fullWidth isRequired={true} validate={[required(), email()]} />
              </Box>
              <Box>
                <TextInput source="firstName" label="First Name" fullWidth />
              </Box>
              <Box>
                <TextInput source="lastName" label="Last Name" fullWidth />
              </Box>
              <Box>
                <TextInput source="notes" label="Notes" fullWidth multiline />
              </Box>
            </Box>
            <Box mr="1em" flex={1}>

              <TagInputArray />

              <BooleanInput source="test" label="Test User" />

              <RadioButtonGroupInput source="territory"
                row={false}
                choices={[
                  { id: 'apac', name: 'APAC' },
                  { id: 'emea', name: 'EMEA' },
                  { id: 'latam', name: 'LATAM' },
                  { id: 'na', name: 'North America' },
                  { id: 'japan', name: 'Japan' },
                ]} />
            </Box>
            <Box mr="1em" flex={1}></Box>

          </Box>
          {/* )} /> */}
        </FormTab>
        <FormTab label="Variables" icon={<Style />} >
          <ArrayInput source="variables" >
            <SimpleFormIterator>
              <TextInput source="name" label="Name" />
              <TextInput source="value" label="Value" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
}