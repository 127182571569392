import * as React from 'react';


import {
  SimpleForm,
  ReferenceInput,
  DateInput,
  AutocompleteInput,
  useInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  required,
  Create,
  SaveContextProvider
} from 'react-admin';



import { useFormState, Field } from 'react-final-form';
import Chip from '@material-ui/core/Chip';
import { TextField as MuiTextField } from '@material-ui/core';
import { isBefore, endOfYesterday } from 'date-fns'
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FieldArray } from 'react-final-form-arrays'



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const nextAvailableSlot = () => {
  const today = new Date();
  return {
    startDate: today.toISOString(),
  }

}

export const validateStartDate = (value, allValues) => {
  let today = endOfYesterday()
  if (isBefore(value, today)) {
    return "Date must be in the future"
  }
  return undefined;
};

const validateStart = [required(), validateStartDate];


const BoundedTextField = props => {
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired
  } = useInput(props);

  const { values } = useFormState();

  const customOnChange = (e) => {

    clearTimeout(values.timeoutId);
    values.timeoutId = setTimeout(() => {

      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (e.target.value !== "") {

        let tempEmails = e.target.value.split(/[ ,\n]+/);

        if (tempEmails.length > 0) {
          tempEmails.forEach(email => {
            if (validRegex.test(email)) {
              values.users.push({ realEmail: email })
              values.touched = true
            }
          })
          let last = tempEmails[tempEmails.length - 1]
          if (validRegex.test(last)) {
            e.target.value = ""
            values.email = ""
          }
          else {
            e.target.value = last;
            values.email = last;
          }
          onChange(e)
        }
      }
    }, 1000);

    return onChange(e)
  }
  return (
    <MuiTextField
      name={name}
      placeholder={values.users.length === 0 ? 'Emails separated by whitespace or comma' : ''}
      label={props.label}
      onChange={customOnChange}
      error={!!(touched && error)}
      helperText={touched && error}
      required={isRequired}
      {...rest}
      variant="filled"
      fullWidth={values.users.length === 0}
    />
  );
};



export const EnrollmentCreateDialog = (props) => {
  const defaultDates = nextAvailableSlot();
  const classes = useStyles();

  return (
    <Create {...props} title="Enroll Students" style={{ width: '100%' }} basePath='bulkcreate' resource='admin/enrollments'>
      <SimpleForm initialValues={{ startDate: defaultDates.startDate, endDate: defaultDates.endDate, labId: null, users: [] }} >
        <Box display="flex" flexDirection="column" style={{ width: '100%' }}>

          <Box display="flex" sx={{ flexDirection: 'row', flexWrap: 'wrap' }} >
            <Box display="flex" flex={2} flexDirection="row">
              <Box flex={2} mr="1em" >
                <ReferenceInput label="Lab" source="labId" optionValue="id" reference="admin/labs" >
                  <AutocompleteInput validate={[required()]} fullWidth />
                </ReferenceInput>
              </Box>
              <Box flex={1} mr="1em" >
                <DateInput source="startDate" label="Start" validate={validateStart} fullWidth />
              </Box>
            </Box>
            <Box flex={1} />
          </Box>





          <Box display="flex" flexDirection="row" >
            <Box flex={2} mr="1em" sx={{ flexDirection: 'row', flexWrap: 'wrap' }} >

              <FieldArray name="users" className={classes.root} initialValue={[]} required={true} allowNull={false} >
                {({ fields }) => (
                  <>
                    {fields.map((address, index) =>

                      <Field name={`${address}.realEmail`} className={classes.chip}>
                        {(field) => {
                          return <Chip
                            label={field.input.value}
                            onDelete={() => fields.remove(index)}
                            className={classes.chip}
                          />
                        }}
                      </Field>

                    )}
                  </>
                )}
              </FieldArray>
              <BoundedTextField source="email"  {...props} className={classes.chip} />

            </Box>
            <Box flex={1} />
          </Box>

          <Box display="flex" flexDirection="row" >
            <Box flex={2} mr="1em" sx={{ flexDirection: 'row', flexWrap: 'wrap' }} >

              <ReferenceArrayInput source="tags" fieldKey="name" reference="admin/tags" >
                <AutocompleteArrayInput
                  optionText="name"
                  optionValue="name"
                  suggestionLimit={5}
                  // onCreate={(value) => {
                  //   const newTagName = value;
                  //   const newTag = { id: null, name: newTagName };
                  //   tags.push(newTagName);
                  //   return newTag;
                  // }}
                  fullWidth
                  source="tags"
                />
              </ReferenceArrayInput>

            </Box>
            <Box flex={1} />

          </Box>
        </Box>
      </SimpleForm>
    </Create >
  )
}