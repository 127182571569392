import React, { useState, useEffect } from 'react';

import TextField from '@material-ui/core/TextField';

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core';


export const Regex = props => {

  const { validation, answered } = props;
  const [answer, setAnswer] = useState('');

  useEffect(() => {
    if (answered) {
      setAnswer(validation.answers[0].answer)
    }
    else if (validation.answer) {
      setAnswer(validation.answer)
    }
    else {
      setAnswer('')
    }
  }, [answered, validation.answer, validation.answers])

  const handleTextFieldChange = (event) => {
    validation.answer = event.target.value
    setAnswer(event.target.value)
  }

  return (
    <Box p={3}>
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend">Use the textfield below to input the requested answer/value.</FormLabel>
        <br />
        <TextField
          name={`regex-${validation.id}`}
          id={`regex-${validation.id}`}
          label="Answer"
          variant="outlined"
          fullWidth
          disabled={answered}
          value={answer}
          required={true}
          onChange={handleTextFieldChange}
          multiline={validation.context.regexM}
          minRows={validation.context.regexM ? 5 : 1}
          maxRows={10}
        />
      </FormControl>
    </Box>
  )
}

export const YesNo = (props) => {
  const { validation, answered } = props;

  const [answer, setAnswer] = useState('yes');

  useEffect(() => {
    if (answered) {
      setAnswer(validation.answers[0].answer ? 'yes' : 'no')
    }
    else if (validation.hasOwnProperty('answer')) {
      setAnswer(validation.answer ? 'yes' : 'no')
    }
    else {
      validation.answer = true;
      setAnswer('yes')
    }
  }, [answered, validation])

  const handleYesNoChange = (event) => {
    const userAnswer = event.target.value;
    validation.answer = userAnswer === "yes" ? true : false;
    setAnswer(userAnswer)
  }
  return (
    <Box p={3}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Select the correct answer</FormLabel>
        <RadioGroup aria-label="yes/no-answer" name="yes/no" value={answer} onChange={handleYesNoChange}>
          <FormControlLabel disabled={answered} value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel disabled={answered} value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
    </Box>
  )
}

export const UserVariable = (props) => {
  const { validation, answered } = props;

  const [answer, setAnswer] = useState('');

  useEffect(() => {
    if (answered) {
      setAnswer(validation.answers[0].answer)
    }
    else if (validation.answer) {
      setAnswer(validation.answer)
    }
    else {
      setAnswer('')
    }
  }, [answered, validation.answer, validation.answers])

  const handleTextFieldChange = (event) => {
    validation.answer = event.target.value
    setAnswer(event.target.value)
  }
  return (
    <Box p={3}>
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend">Use the textfield below to input the requested answer/value.</FormLabel>
        <br />
        <TextField
          id={`user-variable-${validation.id}`}
          label={validation.context.uvariable}
          value={answer}
          variant="outlined"
          fullWidth
          disabled={answered}
          required={true}
          multiline={validation.context.uvariableM}
          minRows={validation.context.uvariableM ? 5 : 1}
          maxRows={10}
          onChange={handleTextFieldChange}
        />
      </FormControl>
    </Box>
  )
}

const handleChange = (event, validation, message) => {
  if (event.target.checked) {
    validation.answer.push(message)
  }
  else {
    const newAnswer = validation.answer.filter(e => e !== message);
    validation.answer = newAnswer;
    // validation.answer.splice(validation.answer.indexOf(message), 1)
  }
};

export const Multiple = (props) => {
  const { validation, answered } = props;
  if (!validation.answer) {
    if (answered) {
      validation.answer = validation.answers[0].answer;
    }
    else {
      validation.answer = []
    }
  }

  return (
    <>
      <Typography variant="h6">
        Use the checkboxes below to select the right answer(s).
      </Typography>
      <FormGroup key={`multiple-${validation.id}`}>
        {
          validation.context.answers.map((option, index) => MultipleOption(validation, answered, index, option.message))
        }
      </FormGroup>
    </>
  )
}

const MultipleOption = (validation, answered, index, message) => {

  const [selected, setSelected] = useState(validation.answer.includes(message));

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={validation.answer.includes(message)}
          onChange={(e) => { if (!answered) { handleChange(e, validation, message); setSelected(!selected) } }}
          name={`option-${index}-${validation.id}`}
          color="primary"
          disabled={answered}
        />
      }
      label={message}
      key={`checkbox-${index}`}
    />
  )
}