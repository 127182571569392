import React from 'react';

import Box from '@material-ui/core/Box';


const ampValidateAnswer = (event, validation) => {
  const answer = {
    activeState: event.player.appState.activeState,
    device: event.player.appState.device,
    ended: event.player.appState.ended,
    medium: event.player.appState.medium,
    playState: event.player.appState.playState,
    time: event.player.appState.time.duration,
    volume: event.player.appState.volume,
    errorCode: event.detail.code,
    errorMessage: event.detail.message,
  };
  validation.answer = answer
  return answer;
}


export const ampValidate = (validation, setLoading) => {
  console.log(JSON.parse(validation.context.ampConfig));
  window.akamai.amp.AMP.create(validation.id, JSON.parse(validation.context.ampConfig))
    .then(event => {
      var amp = event.player;

      amp.addEventListener(window.akamai.amp.Events.PAUSED, (event) => {
        ampValidateAnswer(event, validation);
        setLoading(false)
        return;
      });
      amp.addEventListener(window.akamai.amp.Events.AUTOPLAY_BLOCKED, (event) => {
        ampValidateAnswer(event, validation);
        setLoading(false);
        return;
      });
      amp.addEventListener(window.akamai.amp.Events.ENDED, (event) => {
        ampValidateAnswer(event, validation);
        setLoading(false);
        return;
      })
      amp.addEventListener(window.akamai.amp.Events.ERROR, (event) => {
        ampValidateAnswer(event, validation);
        setLoading(false);
        return;
      })

      // console.log(amp)
      // amp.play()
      //   .then(value => {
      //     console.log(value)
      //   })
    })
    .catch(e => {
      console.log(e)
      // setAnswer(e);
      // validateCallback(e)
      // setMediaPlayState(false);
      return;
    })
}

export const AMP = (props) => {
  const { validation, answered } = props;



  const playerStyle = { //center player
    margin: 'auto',

  };
  return (
    <Box
      p={3}
      className="amp-inline"
      id={validation.id} //a new id is needed in order to refresh after a next/previous
      // data-autoplay="true"
      data-controls="none"
      // data-src={validation.context.ampConfig}
      // data-poster="my poster URL.jpg"
      // data-time="0"
      // data-loop="false"
      data-width="400"
      data-height="300"
      // data-hidden="true"
      style={playerStyle}
    >

    </Box >

  )
}