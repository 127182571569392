// in src/App.js
import * as React from "react";

import { Admin, Layout } from "@react-admin/ra-enterprise";

import { Sidebar } from "react-admin";

import validationAPI from "./validationAPI";

import UserIcon from '@material-ui/icons/People';
import { UserList, UserEdit, UserCreate } from './Admin/users'

import TagIcon from '@material-ui/icons/LocalOffer';
import { TagList } from './Admin/tags'

import ConfIcon from '@material-ui/icons/Settings';
import { ConfList } from './Admin/configuration'

import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { LabList, LabEdit, LabCreate } from "./Admin/labs";

import PostAddIcon from '@material-ui/icons/PostAdd';
import { EnrollmentList } from "./Admin/enrollments";

import { AttemptsList, AttemptSummary } from './Admin/attempts'
import AvTimerIcon from '@material-ui/icons/AvTimer';

import { ValidationEditModal, ValidationCreateModal } from './Admin/validations/validations'

import { NotificationList } from "./Admin/labNotifications";
import TelegramIcon from '@material-ui/icons/Telegram';

import DevicesIcon from '@material-ui/icons/Devices';
import { AssetList } from "./Admin/assets";

import customRoutes from './customRoutes';


import LoginPage from "./Login/LoginPage";

import authProvider from './validationAuth';

import { Resource, Menu } from "@react-admin/ra-rbac";


import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

const messages = {
  en: englishMessages,
};

const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'en', { allowMissing: true });

const customMenu = (props) => <Menu {...props} dense={false} hasDashboard={false} logout={false} />
const customSideBar = (props) => <Sidebar {...props} open={true} />
const CustomLayout = (props) => <Layout {...props} menu={customMenu} sidebar={customSideBar} />;


const App = () => {
  return (
    <Admin
      customRoutes={customRoutes}
      dataProvider={validationAPI}
      authProvider={authProvider}
      loginPage={LoginPage}
      layout={CustomLayout}
      i18nProvider={i18nProvider}
    >
      <Resource name="admin/enrollments" options={{ label: 'Enrollments' }} list={EnrollmentList} icon={PostAddIcon} />
      <Resource name="admin/labs" options={{ label: 'Labs' }} list={LabList} edit={LabEdit} create={LabCreate} icon={AssignmentTurnedInIcon} />
      <Resource name="admin/validations" edit={ValidationEditModal} create={ValidationCreateModal} />
      <Resource name="admin/notifications" options={{ label: 'Notifications' }} list={NotificationList} icon={TelegramIcon} />
      <Resource name="admin/attempts" options={{ label: 'History' }} list={AttemptsList} icon={AvTimerIcon} />
      <Resource name="admin/users" options={{ label: 'Students' }} list={UserList} edit={UserEdit} create={UserCreate} icon={UserIcon} />
      <Resource name="admin/tags" options={{ label: 'Tags' }} list={TagList} icon={TagIcon} />
      <Resource name="admin/configuration" options={{ label: 'System' }} list={ConfList} icon={ConfIcon} />
      <Resource name="attempts" show={AttemptSummary} />
      <Resource name="admin/assets" options={{ label: 'Assets' }} list={AssetList} icon={DevicesIcon} />
      <Resource name="akamai/search/accounts" />
      {/* <Resource name="labs" edit={LabAttempt} /> */}
    </Admin >
  );
}
export default App;