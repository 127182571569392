

const authProvider = {
  // authentication
  login: params => Promise.resolve(),
  checkError: error => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem('token');
    // console.log(params)
    if (!token) {
      return Promise.reject()
    }
    return Promise.resolve()
  },
  logout: () => Promise.resolve(),
  getIdentity: () => {
    const tokenParsed = localStorage.getItem('token-parsed');
    if (!tokenParsed) {
      return Promise.reject()
    }
    const tokenJson = JSON.parse(tokenParsed)
    return Promise.resolve({
      id: tokenJson.preferred_username,
      fullName: tokenJson.name,
    })
  },

  getPermissions: () => {
    const roles = localStorage.getItem('roles')
    const rolesJson = JSON.parse(roles)
    if (rolesJson) {
      return Promise.resolve({
        permissions: [],
        roles: rolesJson,
      });
    }
    else {
      return Promise.reject()
    }
  },
  getRoles: () => {

    //AkamaiU
    if (process.env.REACT_APP_AUTH === 'akau') {
      return Promise.resolve({
        lab_admin: [{ action: "*", resource: "*" }],
        student: [
          { action: "*", resource: "admin/enrollments" },
          { action: ["read", "list", "show"], resource: "admin/users" },
          { action: ["read", "show"], resource: "admin/labs" }
        ]
      });
    }

    return Promise.resolve({
      lab_admin: [{ action: "*", resource: "*" }],
      enrollment: [
        { action: ["read", "list", "create"], resource: "admin/enrollments" },
        { action: ["read", "show"], resource: "admin/users" },
        { action: ["read", "show"], resource: "admin/labs" },
        { type: 'deny', action: 'edit', resource: 'admin/enrollments' }
        // { action: ["list"], resource: "admin/labs" },
        // { action: ["list"], resource: "admin/users" }
      ],
      student: [
        { action: ["read", "show"], resource: "attempts" }
      ]
    });
  },

};
export default authProvider;