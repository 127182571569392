import * as React from "react";
import {
  FormWithRedirect,
  DateInput,
  BooleanInput,
  NumberInput,
  FormDataConsumer,
} from 'react-admin';
import Box from '@material-ui/core/Box';


export const LabsSettings = props => (
  <Box p="1em">
    <Box display="flex">
      <Box flex={2} mr="1em">

        <Box display="flex" flexDirection="column">

          <Box display="flex" flexDirection="row">
            <Box flex={1} mr="0.5em">
              <BooleanInput label="Timed" source="timed" />
            </Box>
            <Box flex={1} ml="0.5em">
              <FormDataConsumer subscription={{ values: true }}>
                {({ formData, ...rest }) => formData.timed &&
                  <NumberInput label="Time" source="time" max={120} min={1} step={1} {...rest} />
                }
              </FormDataConsumer>
            </Box>
          </Box>

          <Box display="flex" flexDirection="row">
            <Box flex={1} mr="0.5em">
              <BooleanInput label="Published" source="published" />
            </Box>
            <Box flex={1} ml="0.5em">
              <FormDataConsumer subscription={{ values: true }}>
                {({ formData, ...rest }) => formData.published &&
                  <DateInput label="Date" source="publishDate" fullWidth  {...rest} />
                }
              </FormDataConsumer>

            </Box>
          </Box>

          <Box display="flex" flexDirection="row">
            <Box flex={1} mr="0.5em">
              <BooleanInput label="Enrollment Required" source="enrollment" />
            </Box>
            <Box flex={1} ml="0.5em">
              <FormDataConsumer subscription={{ values: true }}>
                {({ formData, ...rest }) => formData.enrollment &&
                  <Box display="flex" flexDirection="row" flexWrap="nowrap" >
                    <Box mr={1} flex={1}>
                      <NumberInput label="Weeks to complete" source="enrollmentSpan" max={99} min={1} step={1} fullWidth />
                    </Box>
                    <Box ml={1} flex={1}>
                      <NumberInput label="Capacity Limit" source="capLimit" max={999} min={0} step={1} fullWidth />
                    </Box>
                  </Box>
                }
              </FormDataConsumer>
            </Box>
          </Box>

          <Box display="flex" flexDirection="row">
            <Box flex={1} mr="0.5em">
              <BooleanInput label="Navigation" source="navigaion" />
            </Box>
            <Box flex={1} ml="0.5em">
              <NumberInput
                label="Passing Grade"
                source="passGrade"
                max={100} min={5}
                step={5}
                format={v => v * 20}
                parse={v => parseFloat(v) / 20}
                fullWidth />
            </Box>
          </Box>

          <Box display="flex" flexDirection="row">
            <Box flex={1} mr="0.5em">
              <BooleanInput label="Summary" source="summary" />
              <BooleanInput label="Instant Feedback" source="instaFeedback" />
              <BooleanInput label="Auto Validate" source="autoValidate" />
            </Box>
            <Box flex={1} ml="0.5em">
              <NumberInput label="Attempts Given" source="maxAttempts" max={999} min={1} step={1} fullWidth />
            </Box>
          </Box>

        </Box>
      </Box>
      <Box flex={1}></Box>
    </Box>
  </Box>
);