import * as React from "react";

import {
  // List,
  TextField,
  TextInput,
  Datagrid,
  DateField,
  BooleanField,
  ReferenceField,
  FunctionField,
  TopToolbar,
  FilterButton,
  ExportButton,
  useRecordContext,
  ReferenceInput,
  SelectInput,
  translate,
  Show,
} from 'react-admin';

import { List } from "@react-admin/ra-rbac";


import {
  ShowDialog,
} from "@react-admin/ra-form-layout";

import TimerOffIcon from '@material-ui/icons/TimerOff';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSendOutlined';
import ThumbDownIcon from '@material-ui/icons/ThumbDownOutlined';
import ThumbUpIcon from '@material-ui/icons/ThumbUpOutlined';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDownOutlined';
import SendIcon from '@material-ui/icons/SendOutlined';
import { Tooltip, makeStyles } from '@material-ui/core';

import {
  SelectColumnsButton,
  useSelectedColumns,
} from "@react-admin/ra-preferences";

import { FilterSidebar } from "./attemptsFilters";
import { AttemptShow } from "../Common/attemptShow";

const useStyles = makeStyles({
  passed: { color: 'green' },
  failed: { color: 'red' },
  pending: { color: 'black' },
});

const statusIcons = {
  passed: <ThumbUpIcon />,
  failed: <ThumbDownIcon />,
  pending: <ThumbsUpDownIcon />,
}

const countValidations = (record, completed) => {
  const total = (record.Lab && record.Lab.Validations) ? record.Lab.Validations.length || 0 : 0
  if (!record.results) {
    return 0
  }
  else {
    let search = record.results.filter(v => (v.completed === completed))
    return `${search.length}/${total}`
  }

}

const StatusField = props => {
  const record = useRecordContext(props);
  const classes = useStyles();
  // if (!record) { return null }


  let status = record.passed ? 'passed' : record.completed ? 'failed' : 'pending';

  return (
    <Tooltip title={status}>
      <span className={classes[status]} >
        {statusIcons[status]}
      </span>
    </Tooltip>
  )

}

const ConditionalTimeField = (props) => {
  const record = useRecordContext(props);

  return record && record.completed
    ? <TextField source="timeDiff" label="Duration" />
    : <Tooltip title="incomplete">
      <TimerOffIcon />
    </Tooltip>;
}


const attemptsListColumns = {
  start: <DateField source="startDate" showTime label="Start" />,
  end: <DateField source="endDate" showTime label="End" />,
  time: <ConditionalTimeField label="Duration" sortBy="timeDiff" />,
  // completed: <BooleanField source="completed" label="Completed" />,
  v_completed: <FunctionField label="Validations Completed" render={r => countValidations(r, true)} />,
  v_pending: <FunctionField label="Validations Pending" render={r => countValidations(r, false)} />,
  score: <TextField source="finalScore" label="Attempt Score" />,
  maxScore: <ReferenceField source="LabId" reference="admin/labs" label="Lab Score" link={false}>
    <TextField source="maxScore" label="Lab Score" />
  </ReferenceField>,
  status: <StatusField label="Status" />,
  // passed: <BooleanField source="passed" label="Passed" TrueIcon={ThumbUpIcon} FalseIcon={ThumbDownIcon} />,
  username: <ReferenceField label="Username" source="UserId" reference="admin/users">
    <TextField source="username" label="Username" />
  </ReferenceField>,
  email: <ReferenceField source="LabId" reference="admin/labs">
    <TextField source="name" label="Lab" />
  </ReferenceField>,
  notified: <BooleanField source="notified" label="Notified" TrueIcon={SendIcon} FalseIcon={CancelScheduleSendIcon} />,
}

const attemptsFilters = [
  <TextInput label="User" source="user" alwaysOn />,
  <ReferenceInput label="Lab Select" source="LabId" reference="admin/labs" >
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

const ListActions = (props) => (
  <TopToolbar>
    <FilterButton />
    <SelectColumnsButton
      preference="attempts.list.columns"
      columns={attemptsListColumns}
    />
    <ExportButton />
    {/* Add your custom actions */}
  </TopToolbar>
)

export const AttemptsList = props => {
  const columns = useSelectedColumns({
    preferences: "attempts.list.columns",
    columns: attemptsListColumns,
  });

  return (
    <>
      <List {...props}
        filters={attemptsFilters}
        actions={<ListActions />}
        aside={<FilterSidebar />}
        filterDefaultValues={{ test: false }}
        hasShow={true}
        hasCreate={false}
        sort={{ field: 'startDate', order: 'DESC' }}
      >
        <Datagrid
          // createForm={<ConfEditRow />}
          // editForm={<ConfEditRow />}
          rowClick="show"
        >
          {columns}
        </Datagrid>

      </List>
      <ShowDialog {...props} title={<AttemptTitle />} fullWidth maxWidth="md">
        <AttemptShow />
      </ShowDialog>
    </>
  )
};

const AttemptTitle = translate(({ record, translate }) => {
  return (
    < span >
      {/* {record ? translate('user.edit.username', { username: record.username }) : ''} */}
      {record ? record.Lab.name : ''}
    </span >
  )
});

export const AttemptSummary = (props) => (
  <Show {...props} title={<AttemptTitle />}>
    <AttemptShow />
  </Show>
);




