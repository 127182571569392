import React from 'react';

import {
  TextInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  NumberInput,
  required,
  RadioButtonGroupInput,
} from 'react-admin';

import Box from '@material-ui/core/Box';

export const YesNo = (props) => {
  return (
    <Box display="flex" p={2} justifyContent="center">

      <Box>
        <RadioButtonGroupInput
          source="context.answer"
          label="Correct answer"
          row={false}
          required={true}
          choices={[
            { id: true, name: 'Yes' },
            { id: false, name: 'No' }
          ]} />
      </Box>
    </Box>
  )
}

export const UserVariable = (props) => {
  const { formData } = props
  return (
    <Box display="flex" p={2} justifyContent="center">
      <Box>
        <TextInput source="context.uvariable" label="Variable Name" validate={required()} fullWidth />
        <BooleanInput source="context.uvariableM" label={formData.context.uvariableM ? "Multiline" : "Single line"} />
      </Box>
    </Box>
  )
}

export const Regex = (props) => {
  const { formData } = props
  return (
    <Box p={2} >
      <Box display="flex" flexDirection="row" alignContent="flex-start">
        <Box mr={1}>
          <BooleanInput source="context.regexG" label={formData.context.regexG ? "Global ON" : "Global OFF"} />
        </Box>
        <Box mr={1}>
          <BooleanInput source="context.regexI" label={formData.context.regexI ? "Case insensitive" : "Case sensitive"} />
        </Box>
        <Box mr={1}>
          <BooleanInput source="context.regexM" label={formData.context.regexM ? "Multiline" : "Single line"} />
        </Box>
      </Box>
      <TextInput source="context.regex" label="Regex Expression" validate={required()} fullWidth />
    </Box>
  )
}

export const Multiple = (props) => {
  return (
    <>
      <ArrayInput source="context.answers" label="Options">
        <SimpleFormIterator>
          <FormDataConsumer>
            {({ getSource, scopedFormData }) =>
              <Box display="flex" flexDirection="row" flexWrap="wrap" >
                <Box flex={4} mr={1}><TextInput source={getSource('message')} label="Message" fullWidth /> </Box>
                <Box flex={1} ><NumberInput source={getSource('value')} label="Points" step={1} fullWidth /></Box>
              </Box>
            }
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  )
}
