import React, { useState, useEffect } from 'react';
import { httpClient } from '../../Common/functions'
import { stringify } from 'query-string';

import ion from './../../Common/ion.json'

import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiTextField from "@material-ui/core/TextField";
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box';
import { SelectInput, TextInput, BooleanInput } from 'react-admin';

const apiUrl = process.env.REACT_APP_API_BACKEND;

// const httpClient = fetchUtils.fetchJson;
let timer;

function depProp(base, keys) {
  keys = Array.isArray(keys) ? keys : keys.split('.');

  for (var i = 0; i < keys.length; i++) {
    if (base.hasOwnProperty(keys[i])) {
      base = base[keys[i]]
    }
    else {
      return false;
    }

  }
  return true;
}

function depAdd(base, keys, value) {
  keys = Array.isArray(keys) ? keys : keys.split('.');
  for (var i = 0; i < keys.length - 1; i++) {
    base = base[keys[i]] = base[keys[i]] || {};
  }
  base[keys[i]] = value;
};
//depAdd(test,'level1.level2.level4', 'hola')

function depDel(base, keys) {
  keys = Array.isArray(keys) ? keys : keys.split('.');
  for (var i = 0; i < keys.length - 1; i++) {
    base = base[keys[i]];
  }
  if (base) {
    delete (base[keys[i]]);
  }
};
//depDel(test, 'level1.level2.level4')

export default function Validation(props) {
  const { formData } = props

  const [loading, setLoading] = useState(false)


  const defaultContext = {
    accountSwitchKey: 'B-G-3AA38Z9:1-8BYUX',
    accountName: 'Advanced Technical Training_Akamai Internal',
    groupObject: {
      groupName: "Advanced Technical Training-G-3AA39V0",
      groupId: "grp_170540",
      contractIds: ["ctr_G-3AA39V0"]
    },
    propertyName: '',
    version: 'stagingVersion',
    criteria: 'exists',
    behavior: 'adaptiveAcceleration',
    behaviorCriteria: {},
  }

  if (!formData || !formData.context || !formData.context.groupObject) {
    formData.context = defaultContext;
  }

  const [accountSearch, setAccountSearch] = useState(formData.context.accountName);
  const [accountOptions, setAccountOptions] = useState([]);

  const [accountId, setAccountId] = useState(formData.context.accountSwitchKey);
  const [groupSearch, setGroupSearch] = useState(formData.context.groupObject.groupId);
  const [groupOptions, setGroupOptions] = useState([formData.context.groupObject]);

  useEffect(() => {
    if (accountSearch.length > 2 && !loading) {
      clearTimeout(timer);
      setLoading(true)
      timer = setTimeout(() => {
        const query = {
          filter: JSON.stringify({ name: accountSearch }),
        };
        const url = `${apiUrl}/akamai/search/accounts?${stringify(query)}`;
        httpClient(url).then(({ json }) => {
          setAccountOptions(json)
          setLoading(false)
        });
      }, 1500);
    }

  }, [accountSearch]);

  useEffect(() => {
    if (accountId.length > 2 && !loading) {
      setLoading(true)
      const query = { accountSwitchKey: accountId };
      const url = `${apiUrl}/akamai/search/groups?${stringify(query)}`;
      httpClient(url).then(({ json }) => {
        setGroupOptions(json)
        setLoading(false)
      });
    }

  }, [accountId]);

  const handleChange = (event) => {
    setGroupSearch(event.target.value);
    const newOption = groupOptions.find(o => o.groupId === event.target.value);
    formData.context.groupObject = newOption;
  };

  const customHandleInput = (props) => {
    setAccountSearch(props.target.value)
  }

  const customOnInputChange = (e, v, r) => {
    if (r === 'reset') {
      const newOption = accountOptions.find(o => o.accountName === v);
      if (newOption) {
        formData.context.accountName = newOption.accountName;
        formData.context.accountSwitchKey = newOption.accountSwitchKey;
        formData.context.groupObject = [];
        setAccountId(newOption.accountSwitchKey)
      }
    }
  }

  return (
    <>
      <Box display="flex" flexDirection="row" flexWrap="wrap" >
        <Box flex={2} mr={1}>
          <Autocomplete
            id="ACC Account"
            options={accountOptions}
            getOptionLabel={option => (option.accountName ? option.accountName : "")}
            getOptionSelected={(opt, val) => (accountOptions.find(aOpt => aOpt.accountSwitchKey === opt.accountSwitchKey))}
            value={formData.context}
            onInputChange={(e, v, r) => customOnInputChange(e, v, r)}
            renderInput={params => (
              <MuiTextField
                {...params}
                onChange={e => customHandleInput(e)}
                variant="filled"
                label="Account"
                disabled={loading}
                placeholder="Name or AccountId"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Box>

        <Box flex={2} mb={2}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="validation-group">Group</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={groupSearch}
              onChange={handleChange}
              disabled={loading}
              fullWidth
            >
              <MenuItem value="000" name="empty">
                <em>None</em>
              </MenuItem>
              {
                groupOptions.map(option => <MenuItem value={option.groupId} name={option.groupName}>{option.groupName}</MenuItem>)
              }
            </Select>
          </FormControl>
        </Box>
      </Box>

      <TextInput source="context.propertyName" label="Property Name" fullWidth />

      <Box display="flex" flexDirection="row" flexWrap="wrap">
        <Box mr={1}>
          <SelectInput
            source="context.version"
            label="Criteria"
            choices={[
              { id: 'stagingVersion', name: 'Staging' },
              { id: 'productionVersion', name: 'Production' },
              { id: 'latestVersion', name: 'Latest' },
            ]} />
        </Box>
        <Box mr={1}>
          <SelectInput
            source="context.criteria"
            label="Criteria"
            choices={[
              { id: 'exists', name: 'Exists' },
              { id: 'not exists', name: 'Does not exists' },
              { id: 'behavior', name: 'Behavior' },
            ]} />
        </Box>
        <Box mr={1}>
          <SelectInput
            source="context.behavior"
            label="Behavior"
            disabled={formData.context.criteria !== 'behavior'}
            choices={Object.keys(ion.definitions.catalog.behaviors).map(v => ({ id: v, name: v }))}
          />
        </Box>
        <Box display="flex" flexDirection="column" >

          {formData.context.criteria === 'behavior' ?
            <>
              <Box alignContent="flex-end" justifyContent='center' fullWidth>
                &nbsp;
                <FormLabel component="legend" fullWidth>Properties for {formData.context.behavior}</FormLabel>
                &nbsp;
                <BehaviorOptions {...props} />
              </Box>
            </>
            : null}
        </Box>
      </Box>


    </>
  );
}


const BehaviorOptions = props => {
  const { formData, prpObject, prpName } = props

  const enableProperty = (event, propertyName, defaultValue) => {
    if (event.target.checked) {
      // formData.context.behaviorCriteria[propertyName] = defaultValue;
      depAdd(formData.context.behaviorCriteria, propertyName, defaultValue)
    }
    else {
      // delete (formData.context.behaviorCriteria[propertyName])
      depDel(formData.context.behaviorCriteria, propertyName)
    }
  };


  //prpObject used on recursive call($ref)
  const behaviorObject = prpObject ? prpObject : ion.definitions.catalog.behaviors[formData.context.behavior]


  if (behaviorObject.type === 'object' || behaviorObject.hasOwnProperty('$ref')) {
    const properties = behaviorObject?.properties?.options?.properties || behaviorObject?.properties || [];
    return Object.entries(properties).map((entry) => {
      const propertyName = `${prpName ? `${prpName}.` : ''}${entry[0]}`
      let propertyBody = entry[1]
      let body = `${entry[0]} not supported`;
      let defaultValue = true;
      // console.log(formData.context.behaviorCriteria)
      // console.log(propertyName)
      const propertyEnabled = depProp(formData.context.behaviorCriteria, propertyName) ? true : false;
      // $ref 
      if (propertyBody.hasOwnProperty('$ref')) {
        let refPath = String(propertyBody['$ref']).split('/')
        let tempPrpObject = ion
        refPath.forEach(path => {
          if (path !== '#') {
            tempPrpObject = tempPrpObject[path] || null;
          }
        });
        if (tempPrpObject.type && tempPrpObject.type === 'object') {
          return <BehaviorOptions {...props} prpObject={tempPrpObject} prpName={propertyName} />
        }
      }
      //enum & booleans
      if (propertyBody.hasOwnProperty('enum')) {
        if (typeof propertyBody.default === 'boolean') {
          body = <BooleanInput
            label={propertyName}
            source={`context.behaviorCriteria.${propertyName}`}
            disabled={!propertyEnabled}
            fullWidth
          />
        }
        else {
          defaultValue = propertyBody.default;
          body = <SelectInput
            source={`context.behaviorCriteria.${propertyName}`}
            label={propertyName}
            // disabled={formData.context.criteria !== 'behavior'}
            choices={propertyBody.enum.map(v => ({ id: v, name: v }))}
            disabled={!propertyEnabled}
            fullWidth
          />
        }
      }

      // string
      if (propertyBody.hasOwnProperty('type') && (propertyBody.type === 'string' || propertyBody.type === 'number')) {
        defaultValue = ''
        body = <TextInput
          source={`context.behaviorCriteria.${propertyName}`}
          label={propertyName}
          disabled={!propertyEnabled}
          fullWidth
        />
      }


      return (
        <>
          <Divider />
          <Box display="flex" flexDirection="row" flexWrap="wrap" key={`options-${propertyName}`} fullWidth>
            <Box mr={1}>
              <Checkbox checked={propertyEnabled} onChange={(e) => { enableProperty(e, propertyName, defaultValue) }} />
            </Box>
            <Box>
              {body}
            </Box>
          </Box>
        </>
      )
    })

  }
  else {
    return `${behaviorObject.type} Not supported`
  }
}


