import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Keycloak from "keycloak-js";


const initOptions = {
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT,
  onLoad: "login-required",
};

const keycloak = Keycloak(initOptions);


keycloak.init({ onLoad: initOptions.onLoad })
  .then((auth) => {

    if (!auth) {
      window.location.reload();
    }

    if (keycloak.token && keycloak.refreshToken) {
      // console.log(keycloak)
      localStorage.setItem("token-parsed", JSON.stringify(keycloak.idTokenParsed));
      localStorage.setItem("roles", JSON.stringify(keycloak.realmAccess.roles));
      localStorage.setItem("token", keycloak.token);
      localStorage.setItem("refresh-token", keycloak.refreshToken);
    }

    //React Render
    ReactDOM.render(
      <React.StrictMode><App /></React.StrictMode>,
      document.getElementById('root')
    );

    setInterval(() => {
      keycloak.updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            // console.log('Token refreshed' + refreshed);
            localStorage.setItem("token-parsed", JSON.stringify(keycloak.idTokenParsed));
            localStorage.setItem("roles", JSON.stringify(keycloak.realmAccess.roles));
            localStorage.setItem("token", keycloak.token);
            localStorage.setItem("refresh-token", keycloak.refreshToken);
          }
          // else {
          //   console.warn('Token not refreshed, valid for '
          //     + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
          // }
        })
        .catch(err => {
          alert('Failed to refresh the token, or the session has expired');
          // console.log(err);
          //window.location.reload();
        }
        );

    }, 30000)

  }).catch(err => {
    // console.log(err)
    // console.error("Authenticated Failed");
    window.location.reload();
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
