import * as React from "react";

import {
  Datagrid,
  TextField,
  Edit,
  TextInput,
  TabbedForm,
  BooleanField,
  FormTab,
  ArrayInput,
  SimpleFormIterator,
  FunctionField,
  NumberField,
  FormDataConsumer,
  Button,
  Create,
  translate,
  ReferenceManyField,

} from 'react-admin';

import { List } from "@react-admin/ra-rbac";


import { AccordionSection } from '@react-admin/ra-form-layout';

import RichTextInput from 'ra-input-rich-text';
import { Link } from 'react-router-dom';

import { EnrollmentReference } from "./enrollments";
import { FilterSidebar } from "./labsFilters";
import { LabsSettings } from './labsSettings';
import { TagInputArray, TagIdsArray } from "./tags";
import { ValidationList } from "./validations/validations";
import { NotificationGrid } from './labNotifications'
import ValidationEditButton from "./validations/validationEditButton"

import { Tooltip, Box, Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import PersonAddDisabled from '@material-ui/icons/PersonAddDisabledOutlined';
import Settings from '@material-ui/icons/Settings';
import Note from '@material-ui/icons/Note';
import Style from '@material-ui/icons/Style';
import Group from '@material-ui/icons/Group';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import LaunchIcon from '@material-ui/icons/Launch';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(3),
    float: "right",
    position: "relative",
  },
}))

const AddNewNotificationButton = ({ record }) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.button}
      variant="text"
      component={Link}
      to={{
        pathname: "/admin/notifications/create",
        // Here we specify the initial record for the create view
        state: { LabId: record.id },
      }}
      label="Create"
    >
      <AddIcon />
    </Button>

  );
}

const AddNewValidationButton = ({ record }) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.button}
      variant="text"
      component={Link}
      to={{
        pathname: "/admin/validations/create",
        // Here we specify the initial record for the create view
        state: { LabId: record.id },
      }}
      label="Create"
    >
      <AddIcon />
    </Button>
  );
}

const LabTitle = translate(({ record, translate }) => (
  < span >
    {record ? record.name : ''}
  </span >
));

const postFilters = [
  <TextInput label="Search" source="name" alwaysOn />,
];

const EnrollmentCounter = (record) => {
  if (record.enrollment) {
    return `${record.enrollments}`
  }
  else {
    return <Tooltip title="Disabled">
      <PersonAddDisabled />
    </Tooltip>;

  }
}

const OpenLabButton = (record) => <Button
  href={`#/labs/${record.id}`}
  rel="noreferrer"
  label="Open"
  key="openLab"
>
  <LaunchIcon />
</Button>

export const LabList = props => (

  <List {...props} filters={postFilters} aside={<FilterSidebar />} hasCreate>

    <Datagrid rowClick="edit">
      <TextField source="name" label="Lab" />
      <FunctionField label="Link" source="id" sortBy="name " render={OpenLabButton} />
      <FunctionField label="Active Enrollments" source="enrollments" sortBy="enrollments " render={EnrollmentCounter} />
      <BooleanField source="published" />
      <NumberField source="validations" label="Validations" />
      <NumberField source="attempts" label="Atempts" />
      <NumberField source="users" label="Unique Users" />
      <TagIdsArray />
    </Datagrid>
  </List >
);


export const LabEdit = (props) => {
  const classes = useStyles();
  return (
    <Edit title={<LabTitle />}  {...props}>
      <TabbedForm warnWhenUnsavedChanges>
        <FormTab label="Lab Info" icon={<Note />}>


          <TextInput source="name" fullWidth label="Lab Title" />

          <AccordionSection label="Description" fullWidth defaultExpanded={true}>
            <RichTextInput source="description" label={false} />
          </AccordionSection>

          <AccordionSection label="Message on Success" fullWidth>
            <RichTextInput source="successMessage" label={false} />
          </AccordionSection>

          <AccordionSection label="Message on Failure" fullWidth>
            <RichTextInput source="failMessage" label={false} />
          </AccordionSection>

          <AccordionSection label="Message while Pending" fullWidth>
            <RichTextInput source="pendingMessage" label={false} />
          </AccordionSection>
        </FormTab>

        <FormTab label="Settings" icon={<Settings />}>
          <LabsSettings {...props} />
        </FormTab>

        <FormTab label="Validations" icon={<LibraryAddCheckIcon />}>
          <AddNewValidationButton />
          <ReferenceManyField label="" reference="admin/validations" target="LabId" fullWidth >
            <Datagrid rowClick="edit" >
              <TextField source="order" sortable={false} />
              <TextField source="message" sortable={false} />
              <TextField source="type" sortable={false} />
              <TextField source="value" sortable={false} />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>

        <FormTab label="Tags and Vars" icon={<Style />}>
          <TagInputArray />
          <ArrayInput source="variables">
            <SimpleFormIterator>
              <FormDataConsumer>
                {({ getSource, scopedFormData }) =>
                  <Box display="flex" flexDirection="row" flexWrap="wrap">
                    <Box flex={1} mr="0.5em"><TextInput source={getSource('name')} label="Name" fullWidth /> </Box>
                    <Box flex={1} ml="0.5em"><TextInput source={getSource('value')} label="Value" fullWidth /></Box>
                  </Box>
                }
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Notifications" icon={<MailOutlineIcon />}>
          <AddNewNotificationButton />
          <ReferenceManyField label="" reference="admin/notifications" target="LabId" fullWidth >
            <NotificationGrid />
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Enrollments" icon={<Group />}>
          <EnrollmentReference target="LabId" omit={['lab']} />
        </FormTab>
      </TabbedForm>
    </Edit >
  );
};


export const LabCreate = (props) => {
  const classes = useStyles();
  return (
    <Create {...props} >
      <TabbedForm initialValues={{
        description: "",
        enrollment: true,
        enrollmentSpan: 4,
        environment: [],
        failMessage: "",
        instaFeedback: true,
        maxAttempts: 3,
        name: "",
        navigation: true,
        passGrade: 4,
        pendingMessage: "",
        publishDate: "2021-01-01",
        published: false,
        successMessage: "",
        summary: true,
        tags: [],
        time: "45",
        timed: false,
        capLimit: 80,
        variables: [],
      }}
        warnWhenUnsavedChanges
      >
        <FormTab label="Lab Info" icon={<Note />}>

          <TextInput source="name" fullWidth label="Lab Title" />

          <AccordionSection label="Description" fullWidth defaultExpanded={true}>
            <RichTextInput source="description" label={false} />
          </AccordionSection>

          <AccordionSection label="Message on Success" fullWidth>
            <RichTextInput source="successMessage" label={false} />
          </AccordionSection>

          <AccordionSection label="Message on Failure" fullWidth>
            <RichTextInput source="failMessage" label={false} />
          </AccordionSection>

          <AccordionSection label="Message while Pending" fullWidth>
            <RichTextInput source="pendingMessage" label={false} />
          </AccordionSection>
        </FormTab>

        <FormTab label="Settings" icon={<Settings />}>
          <LabsSettings />
        </FormTab>

        <FormTab label="Validations" icon={<LibraryAddCheckIcon />}>
          {/* <LabsValidations {...props} /> */}
          <ArrayInput source="validations" fullWidth label="">
            <SimpleFormIterator  >
              <FormDataConsumer>
                {({ getSource, scopedFormData }) => {
                  return (
                    <TextField
                      source={getSource('type')}
                      record={scopedFormData}
                    />
                  );
                }}
              </FormDataConsumer>
              {/* <Box display="flex" flexDirection="row">
                <Box><TextInput label="Message" source="message" fullWidth /></Box>
                <Box><TextInput label="Type" source="type" fullWidth /></Box>
              </Box> */}

            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Tags and Vars" icon={<Style />}>
          <TagInputArray />
          <ArrayInput source="variables">
            <SimpleFormIterator>
              <TextInput source="name" label="Name" />
              <TextInput source="value" label="Value" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Enrollments" icon={<Group />}>
        </FormTab>
      </TabbedForm>
    </Create >
  );
};